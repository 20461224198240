
.resourceCard {
    /* width: 48.5rem;
    height: 17.7rem; */
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    border-radius: 2rem;
    padding: 2.25rem;
}


.qText {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.qTextDescription{
    color: var(--color-gray-02);
}
.qIcon {
    height: 4rem;
    width: 4rem;
}

.qIcon svg {
    height: 100%;
    width: auto;
    object-fit: contain;
}


@media (min-width: 430px) {


    .resourceCard {

        gap: 8rem;

    }
}

@media (min-width: 768px) {

    .qIcon {
        height: 5rem;
        width: 5rem;
    }

}

@media (min-width: 1024px) {
    .resourceCard {
        gap: 4rem;
        border-radius: 2rem;
        padding: 2rem 3rem;
    }
    .qIcon {
        height: 6rem;
        width: 6rem;
        align-self: flex-start;
    }



}

@media(min-width:1280px) {
    .imageWrapper {
        height: 50rem;
    }

    .resourceIcon {
        height: 7rem;
        width: 7rem;
        align-self: center;
    }

    .resourceCard {
        /* width: 48.5rem; */
        height: 17.7rem;
    }

}
@media(min-width:1920px) {
    .imageWrapper {
        height: 50rem;
    }

    .resourceIcon {
        height: 7rem;
        width: 7rem;
        align-self: center;
    }

    .resourceCard {
        width: 48.5rem;
        height: 17.7rem;
    }

}
/* @media(min-width:1280px) {
    .imageWrapper {
        height: 50rem;
    }

    .resourceIcon {
        height: 7rem;
        width: 7rem;
        align-self: center;
    }

    .resourceCard {
        width: 48.5rem;
        height: 17.7rem;
    }

} */