.a{
    position: relative;
    background-color: aqua;
}
.b{
    position: absolute;
}
.aList {
    display: flex;
    flex-direction: row;
    width: 60rem;
    height: 27rem;
    background-color: var(--color-gray-06);
    border-left: 1px solid var(--color-gray-04);
    border-right: 1px solid var(--color-gray-04);
    border-bottom: 1px solid var(--color-gray-04);
    padding: 4rem 0 0 4rem;
    margin-top: 3rem;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    /* position: relative; */
}

.aList a {
    text-decoration: none;
}

.aList li {
    list-style: none;
}

