.cardContainer{
    background-color: white;
    border: 1px solid var(--color-gray-05);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 1rem;

}

.cardTitleWrapper{
    /* padding: 1rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.cardTitle{
    font-size: 1.8rem;
    color: var(--color-gray-02);
}
.cardData{
    font-size: 3.2rem;
    color: var(--color-gray-01);  
    font-family: var(--text-semibold); 
    margin-top: .5rem;
}
.cardFooterWrapper{
    border-top: 1px solid var(--color-gray-03);
 padding-top: 1rem;
}
.cardFooter{
    font-size: 1.6rem;
    color: var(--color-gray-01);
}

.noDataCardContainer{
    background-color: var(--color-gray-05);
    background-color: white;
    border: 1px solid var(--color-gray-04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 1rem;
    height: 33rem;
    gap: 1rem;
}
.noDataTextWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.noDataTitle{
    color: var(--color-gray-02);
    text-align: center;
}
.noDataIconWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.noDataIcon1{
    height: 4.4rem;
    width: 4.4rem;
    background: rgb(255, 216, 216);
    border: 10px solid rgb(255, 216, 216);
    border-radius: 10rem;
}
.noDataIcon2{
    height: 7rem;
    width: 7rem;
}
.noDataIcon1 svg, .noDataIcon2 svg{
    height: 100%;
    width: 100%;
    object-fit: contain;
}