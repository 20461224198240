.backdropContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(5px);
    /* For Safari */
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.307);
    z-index: 10;
}
.overlayContainer {
    position: fixed;
    top: 0;
    /* top: 10%; */
    left: 50%;
    transform: translateX(-50%);
 
    z-index: 10;
   
    /* height: 100%; */
    width: 100%;
}



@media print {
    .overlayContainer{
        display: none;
    }
}

@media (min-width: 1024px) {
    .overlayContentWrapper{
        max-width: 50rem;
    }
    .overlayContainer {
       
      
        right: 0;
        left: auto;
        /* This will place the left edge at the middle of the viewport */
        transform:none;
        /* This will pull the modal back to its center */
        width: 50rem;
        /* Adjust width as desired, e.g., 500px, 60%, etc. */
    }
}
/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/