.scrollButtonsContainer {
    display: flex;
    width: 176px;
    align-items: center;
    gap: 20px;
}

.scrollButton {
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
    padding: 10px 19px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 27.5px;
    /* background-color: var(--color-gray-05); */
    background-color: var(--color-gray-07);
    border: 1px solid var(--color-gray-04);
}

.scrollButton:hover{
    cursor: pointer;
}
.scrollButton.disabled {
   background-color: var(--color-gray-04);
    color: white;
    cursor: not-allowed;
    /* opacity: 0.5; */
  }

  @media (min-width: 1024px) {
    .scrollButton {
   
        width: 5.5rem;
        height: 5.5rem;
        padding: 21px 24px;   
        border-radius: 27.5px;
    
    }
    
  }