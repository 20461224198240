.pageHeaderContainer {
    padding: 0;
    background-color: var(--color-gray-06);
    border-top: 1px solid var(--color-gray-04);
    z-index: 0;
    /* background: var(--color-gray-01); */
}

.contentWrapper {
    padding: 3rem 2rem;
}

.heroGridContainer {
    display: grid;
    grid-template-columns: repeat(12, auto);
    grid-template-rows: 1fr;
    min-height: 20rem;
    /* height: 20rem; */
}



.heroGridWrapper {
    grid-row: 1;
    grid-column: 1/-1;
    /* min-height: 20rem; */
    display: flex;
    align-items: center;
    z-index: 1;
    /* width: 80%; */
    /* padding-bottom: 2rem; */
}

.gridItem1 {
    display: flex;
    flex-direction: column;

    /* gap: 1rem; */
}

.gridItem1TextWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.headlineTitles {
    /* line-height: 1.5;
   letter-spacing: .5px; */
}

.gridItem2 {

    display: none;
}

.title {
    color: var(--color-gray-01);
}

.subtitle {
    color: var(--color-gray-03);
}


.description {
    color: var(--color-gray-01);
    /* margin-top: 2rem; */
}

.heroCallout {
    color: var(--color-gray-03);
    margin-top: 2rem;
}

.chartImageWrapper {
    /* height:21rem ;
    width: 32rem; */
    background-color: white;
    /* border-radius: 2rem; */
    border: 1px solid var(--color-gray-04);
}

.chartImageWrapper img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.chartBodyContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0;

    /* gap: 4rem;
    margin: 4rem 0; */
}

.ctaLine {


    display: flex;
    align-items: center;
    gap: 2rem;
}

.ctaLinkText a {
    color: var(--color-red-03);
    font-weight: 600;
    padding-left: .7rem;
}

/* @media (min-width: 430px) {
    .chartBodyContainer {
        gap: 2rem;   
        background: red;
        padding: 20rem;
    }
}

@media (min-width: 768px) {
    .chartImageWrapper {
        border-radius: 2rem;
    }
}

@media (min-width: 1024px) {
    .chartBodyContainer {
        gap: 4rem;
     
    }
} */

@media (min-width: 430px) {
    .heroGridContainer {

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        height: auto;
        justify-content: center;
        align-items: center;
        min-height: 20rem;
    }

    .heroGridWrapper {

        grid-column: 1/-1;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto;
    }

    .gridItem1 {
        grid-column: 1/-1;
        /* grid-column: 1/10; */
    }

}

@media (min-width: 768px) {

    .heroGridContainer {
        min-height: 32rem;
    }
    .heroGridWrapper{
        gap: 2rem;
    }
    .gridItem1 {
        grid-column: 1/8;
    }

    .gridItem2 {
        grid-column: 8/-1;
        grid-row: 1;

        display: flex;
        justify-content: flex-end;
    }

    .gridItem2Image {
        width: 80%;
        height: auto;
    }

    .gridItem2Image img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .contentWrapper {
        padding: 2rem 4rem 2rem 4rem;
        /* padding: 3rem 4rem; */
    }
    

}
@media (min-width: 834px) {
    .heroGridContainer {
        /* min-height: 45rem; */
    }
    .heroGridWrapper{
        gap: 4rem;
    }
    .gridItem2Image {
       max-height: 45rem;
     
    }
    .gridItem1 {
      padding-right: 4rem;
    }
    .contentWrapper {
        padding: 0 4rem;
        padding: 3rem 4rem;
    }



}
@media (min-width: 1080px) {
    .heroGridContainer {
        min-height: 49rem;
        gap: 4rem;
    }
    
    .gridItem1 {
        grid-column: 1/7;
        

    }

    .gridItem2 {
       
        grid-column: 8/-1;
        width: auto;
    }


  
  
}

@media (min-width: 1280px) {
    .heroGridContainer {

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        height: auto;
        justify-content: center;
        align-items: center;

        /* background-color: var(--color-gray-06);
        padding-top: 8rem;
        padding-left: 8rem;
        padding-right: 8rem; */
        /* grid-column: 1/-1;
        grid-row: 1; */
        /* padding: 4rem 8rem; */
    }

    .heroGridWrapper {
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        column-gap: 12rem;
        align-items: center;
        /* padding: 4rem 0; */
        /* min-height: 50rem; */
        width: 100%;

    }

    .gridItem1 {
        grid-column: 1/7;
    }
    .gridItem2 {
        /* grid-column: 7/-1; */
        grid-column: 7/12;
        justify-content: flex-end;
    }
    .gridItem2Image {
      width: 100%;
     
    }

}

@media (min-width: 1440px) {
    .contentWrapper {
        padding: 0 4rem;
    }


    .gridItem1 {
        grid-column: 1/7;
        grid-row: 1;
    }

    .gridItem2 {
        display: flex;


        grid-column: 7/-1;
        justify-content: flex-end;
       
        grid-row: 1;
    }

    .gridItem2Image {
        height: 50rem;
        width: auto;
        /* padding: 3rem 0; */
    }

    .gridItem2Image img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 1920px) {
    .contentWrapper {
        padding:  4rem 0rem;
    }

    .gridItem1 {
        grid-column: 1/8;
    }
    .gridItem2{
        grid-column: 7/-1;
       
    }

    .gridItem2Image {
        height: 55rem;
        width: auto;
    }

}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint

@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/