.contentWrapper {
  padding: 0 2rem;
}

.stickyNavContainer {
  /* font-size: 1.5rem; */
  /* position: -webkit-sticky; 
  position: sticky; */
  top: 0;
  /* background-color: var(--color-gray-01); */
  /* display: grid;
  grid-template-columns: repeat(12,1fr);
  flex-direction: row;  */
  /* z-index: 1; */
  /* padding: 1rem 0; */
}

.stickyNavWrapper {
  /* display: grid;
  grid-template-columns: repeat(12,1fr); */
  /* column-gap: 4rem; */
  /* padding: 0 8rem; */
  /* grid-column: 1/-1; */
  /* border-top: 1px solid var(--color-gray-03);
  border-bottom: 1px solid var(--color-gray-03); */
  /* background-color: var(--color-gray-01); */
}

.stickyNav {
  display: flex;
  gap: .5rem 1rem;
}

/* For WebKit-based browsers */
.stickyNav::-webkit-scrollbar {
  /* width: 5px;
  height: 8px; */
  display: none;
}

.stickyNav::-webkit-scrollbar-track {
  background: var(--color-gray-07);

}

.stickyNav::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-07);
  border-radius: 10px;
  border: 3px solid var(--color-gray-07);
}

.stickyNav::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-red-02);
}

.stickyNavContainer a {
  text-decoration: none;
  color: var(--color-gray-01);
  padding: 2rem 0;

}

@media (min-width: 430px) {
  .stickyNav {
    gap: .5rem 2rem;
  }
}

@media (min-width:1024px) {
  .stickyNav {
    gap: .5rem 3rem;
  }
}

@media (min-width: 1440px) {
  .contentWrapper {
    padding: 0rem 4rem;
  }

  /* .stickyNav{
    gap: .5rem 3rem;
  } */
}

@media (min-width: 1920px) {
  .contentWrapper {
    padding: 0rem;
  }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/