.section{
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem; 
}
/* .checkboxLabel{
    background-color: rgb(91, 88, 137);
    padding: 1rem;
    font-size: 10rem;
} */
.sectionContent{
    display: flex;
    flex-direction: column;
    /* width: 50%; */
}
.sectionHeader{
    border-bottom: 1px solid var(--color-gray-04);
    padding-bottom: 1rem;

}
.logoSectionTitle{
    margin-top: 3rem;
}
.formButtonWrapper{
    padding: 4rem 0;
}
.footerButtonWrapper button{
    max-width: max-content;
    background-size: 400% 400%;
    background-image: linear-gradient(100deg, red, black, var(--color-red-03));
    animation: gradientAnimation 10s ease infinite;
    color: white;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100rem;
    border: 1px solid var(--color-red-03);
    
}

.formButton{
      max-width: 50%;
}

.formFooter{
    /* background-color:white; */
    padding: 2rem 0;
    border-radius: 2rem;
    max-width: 70rem;
}
.formFooter > button{

}
.footerSectionTitle{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-bottom: 3rem;
}
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}