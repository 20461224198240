.overlayContainer {
    position: fixed;
    top: 10%;
    padding: 4rem;
    height: auto;
    width: 100%;
    /* width: 100%; */
    z-index: 100;
}

.alertContainer {
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    /* z-index: 100;
    background-color: #f78b8b; */
}

.productAlert {

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    border-radius: 4rem;
    gap: 1rem;
    color: var(--color-gray-01);
    background-color: var(--color-gray-07);
    border: 1px solid var(--color-gray-04);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}


.productAddedLG {
  
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 4rem;
    gap: 1rem;
    border: 1px solid var(--color-gray-04);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-size: 400% 400%;
    background-image: linear-gradient(45deg, #f78b8b, #ffb5b5, rgb(255, 224, 224), var(--color-gray-07), #ffb5b5, #f78b8b);
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.productAddedLG img {
    height: 3rem;
    width: auto;
    /* width: 5rem; */
}

@media (min-width: 430px) {
    .alertContainer {
        /* position: fixed;
        top: 10%;
 
        width: 100%;
    max-width: 40rem; */
    }

    /* .productAddedLG {
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-radius: 4rem;
        gap: 1rem;
        border: 1px solid var(--color-gray-04);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        background-size: 400% 400%;
        background-image: linear-gradient(45deg, #f78b8b, #ffb5b5, rgb(255, 224, 224), var(--color-gray-07), #ffb5b5, #f78b8b);
        animation: gradientAnimation 15s ease infinite;
    } */
}



@media print {
    .alertContainer {
        display: none;
    }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/