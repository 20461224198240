.featureDefinitionsPageContainer {
    /* background-color: var(--color-gray-07); */
    background-color: white;
    border-radius: 2rem;
}
.featureDef{
    padding: 2rem 3rem;
}
.pageHeaderContainer {
    padding: 0;
}

.shortDescription {
    display: block;
}

.longDescription {
    display: none;
}

.contentWrapper {
    padding: 2rem;
}

.heroGridWrapper {
    min-height: 20rem;
    display: flex;
    align-items: center;
    /* width: 80%; */
    /* padding-bottom: 2rem; */
}
.gridItem1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.gridItem2 {
    display: none;
}

.categoryTitleWrapper {
    font-family: var(--text-semibold);
    color: black;
    padding-bottom: 1rem;
}

.featureTitleWrapper {
    font-family: var(--text-semibold);
    color: var(--color-red-02);
    padding-bottom: 1rem;
}
.featureDescriptionWrapper{
    margin-bottom: 1rem;
    color: var(--color-gray-01);
}
.featureDescriptionWrapper:not(:last-child){
   
}
.definitionWrapper:not(:last-child) { 
    border-bottom: 1px solid var(--color-gray-04);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
/* }
 */

/* .definitionWrapper:not(:last-child) { */
/* .definitionWrapper{
    border-bottom: 1px solid var(--color-gray-04);
  
} */

.definitionWrapper:last-child {
    /* padding-bottom: 4rem; */
    padding-bottom: 1rem;
}

.stickyNavContainer {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    border-bottom: 1px solid var(--color-gray-04);
    /* border-top: 1px solid var(--color-gray-04); */
}

.subtitle {
    color: var(--color-gray-02);
}

.title {
    color: var(--color-gray-01);
}

.description {
    color: var(--color-gray-03);
}

@media (min-width: 768px) {
    .featureDef{
        padding: 3rem 4rem;
        margin-top: 4rem;
    }
}


@media (min-width: 1280px) {
    .contentWrapper {
        padding: 1rem 2rem 3rem 2rem;
    }
    .heroGridContainer {

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        height: auto;
        justify-content: center;
        align-items: center;

        /* background-color: var(--color-gray-06);
        padding-top: 8rem;
        padding-left: 8rem;
        padding-right: 8rem; */
        /* grid-column: 1/-1;
        grid-row: 1; */
        /* padding: 4rem 8rem; */
    }

    .heroGridWrapper {
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        column-gap: 12rem;
        align-items: center;
        /* padding: 4rem 0; */
        /* min-height: 50rem; */
        width: 100%;

    }

    .gridItem1 {
        grid-column: 1/8;
    }

   
    .longDescription {
        display: block;
    }

    .shortDescription {
        display: none;
    }
}

@media (min-width: 1440px) {
    .contentWrapper {
        padding: 0 4rem;
    }
    .contentWrapper {
        padding: 1rem 4rem 4rem 4rem;
    }

    .gridItem1 {
        grid-column: 1/6;
        grid-row: 1;
    }

    .gridItem2 {
        display: flex;
        /* border-top-left-radius: 100rem;
        border-top-right-radius: 100rem; */

        grid-column: 6/-1;
        justify-content: flex-end;
        /* background-image: url('/public/assets/image/backgrounds/main/lg-mom-shape.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; */
        grid-row: 1;
    }

    .gridItem2Image {
        height: 50rem;
        width: auto;
    }

    .gridItem2Image img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 1920px) {
    .contentWrapper {
        padding: 2rem 0rem;
    }
    .gridItem1 {
        grid-column: 1/6;
    }
    .gridItem2Image {
        height: 70rem;
    }
    
    .featureDef{
        padding: 4rem 8rem;
        margin-top: 4rem;
    }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/