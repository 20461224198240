.notFoundPageContainer {
    background-color: var(--color-red-01);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    height: 100%;
    color: white;
    padding: 2rem;
}
.notFoundPageContainer a{
    color: black;
    text-decoration: underline;
}
.notFoundPageWrapper {
    grid-column: 1/-1;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: 8rem ;
}

.notFoundPage {
    grid-column: 2/12;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.gifLogo {
    height: 30rem;
    object-fit: contain;
}

@media (min-width: 1024px) {
    .gifLogo {
        height: 50rem;
    }
}
/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/