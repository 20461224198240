.accountInformationWrapper{
    
    /* background-color: var(--color-gray-07); */
}

.contentWrapper{
    /* padding: 2rem 0; */
    padding: 2rem;
}
.accountInformationFooter{
    padding-bottom: 4rem;

}
.sectionTitle{
    color:var(--color-gray-01);
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
}
.sectionDescription{
    color:var(--color-gray-02);
}
.passwordInformationWrapper{
    background-color: var(--color-gray-07);
}
.buttonWrapper button{
    max-width: max-content;
}

.actionButtonsWrapper{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.actionButtonsWrapper a{
     font-size: 1.6rem;
    color: var(--color-gray-03);
}

.actionButton:hover{
    cursor: pointer;
}

.deleteAccountWrapper{
    font-size: 1.6rem;
    padding-top: 3rem;
    display: flex;
    justify-content: flex-end;
    color: var(--color-gray-03);
}
@media (min-width: 1280px) {
    .contentWrapper{
        padding: 2rem;
    }
    .contentWrapperBase{
        padding: 2rem 4rem 2rem 4rem;
    }
}@media (min-width: 1440px) {
    .contentWrapper{
        padding: 3rem 4rem;
    }
}
@media (min-width: 1920px) {
    .contentWrapper{
        padding: 8rem 0;
    }
}