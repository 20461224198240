.applianceSectionContainer{
    padding: 0 2rem;
}
.titleContainer{
    padding-bottom: 2rem;
}

.upcListWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.upcListItem {
    list-style: none;
}
@media (min-width: 768px) {
    .applianceSectionContainer{
        padding: 0 4rem;
    }
}
@media (min-width: 1920px) {
    .applianceSectionContainer{
        padding: 0rem;
    }
}