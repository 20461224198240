.qrcodeContainer {
    
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    width: auto;
    gap: 1rem;
    margin-right: 2rem;
}

.imageWrapper{
    height: 5rem;
    width: 5rem;
}
.imageWrapper img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.title{
    font-size: 1.4rem;
    /* text-align: center; */
}
.subtitle{
    font-size: 1.4rem;
}
.description{
    font-size: 1.4rem;
}