/* EMPTY LIST */

.a {
    position: relative;
    background-color: var(--color-gray-05);
    min-height: 100vh;
    /* min-height: 100%; */
}

.emptyProductListBodyContentMobile {

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    justify-items: center;
    min-height: 100%;
    padding: 0 4rem;
}

.mobileCloseIcon {
    grid-row: 1;
    padding: 2rem;
    border-radius: 100rem;
    display: flex;
}

.mobileCloseIcon svg {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
}

.mobileEmptyListHeaderText {
    grid-column: 1/-1;
    grid-row: 2;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
}

.mobileEmptyListCharacterImage {
    grid-column: 1/-1;
    grid-row: 3;
    height: 30rem;
    width: 30rem;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileEmptyListCharacterImage img {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsWrapper {
    grid-row: 4;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

/* POPULATED LIST */

.populatedListScreenContainer {
    /* padding: 2rem 0; */
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    /* background-size: 400% 400%;
    background-image: linear-gradient(45deg, #f78b8b, #ffb5b5, rgb(255, 224, 224), var(--color-gray-07), #ffb5b5, #f78b8b);
    animation: gradientAnimation 15s ease infinite; */

 background-color: var(--color-gray-05);
 /* height: 100vh; */
    /* z-index: 1000; */
}
.productCountWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    /* justify-content: space-between; */
    gap: 1rem;
}
.populatedListHeader {
   display: flex;
   flex-direction: column;
   border-bottom: 1px solid var(--color-gray-04);

   padding: 2rem 2rem 2rem 2rem ;
   gap: .2rem;
}
.listHeaderDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.listHeaderTitleContainer{
    display: inline-flex;
    width: max-content;
}
.listHeaderTitle{
    color: var(--color-red-02);
    font-family: var(--text-semibold);
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}
.listHeaderTitle svg{
    display: flex;
    align-items: center;
    justify-content: center
}
.populatedListBackIconWrapper{
    /* align-self: flex-end; */
    align-self: baseline;
    display: inline-flex;
    /* margin-bottom: 2rem; */
}
.populatedListBackIconWrapper svg{
    height: 3.4rem;
    width: 3.4rem;
    /* background-color: var(--color-gray-06); */
    border-radius: 100rem;
    /* border: 1px solid var(--color-gray-04); */
    padding: .7rem;
    /* stroke: var(--color-gray-04);
    fill: var(--color-gray-04); */
}
.populatedListBackIconWrapper:hover{
    cursor: pointer;
}
.populatedProductsList{

position: absolute;
right: 0;
width: 100%;
padding-bottom: 8rem;
height: 100vh;
}
.scrollProductList{
    /* overflow-y: scroll; */
    overflow-y: auto;
    height: 100%;
    /* background-color: blue; */
    /* height: 90rem; */
    /* height: max-content; */
    background-color: var(--color-gray-07);
    padding-bottom: 10rem;
    /* scroll-snap-type: y proximity; */
    /* scroll-snap-type: y mandatory; */
    scroll-behavior: smooth;
  
}
.scrollProductList > div {
    /* scroll-snap-align: start; */

}
.scrollProductList::-webkit-scrollbar {
    width: 1rem;
  }
  .scrollProductList::-webkit-scrollbar-track {
    background: var(--color-gray-06);
  }
  
  .scrollProductList::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-04);
    border-radius: 10px;
    border: 1px solid var(--color-gray-05);
  }
  
  .scrollProductList::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-gray-03);
  }

.listFooterWrapper{
    padding: 2rem 2rem 0 2rem;
    margin-bottom: 8rem;
}
.listFooterWrapper svg {
    height: 2rem;
    width: 2rem;
}

@media (min-width: 1024px) {
    .emptyProductListBodyContentMobile {

   
        padding: 0rem;
    }
}