.desktopAppliancePageContainer {
    display: none;
}

.mobileAppliancePageContainer {
    background-color: var(--color-gray-07);
}

/* HEADER  */
/* .gridContainer1 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row: 1fr;
    padding: 4rem 0;
}

.gridWrapper1 {
    grid-column: 1/-1;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row: 1fr;
}

.gridItem1 {
  
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.titleText {
    color: var(--color-gray-03);
}

.subtitleText {
    color: var(--color-gray-01);
}


.buttonWrapper button {
    width: 100%;
}

.colorButtonWrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;
} */

/* ---------------------------------------------------- */
/* MOBILE HEADER */
/* ---------------------------------------------------- */
/* .mobileHeaderWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
}

.mobileProductImageWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: auto;
    justify-items: center;
    align-items: center;
}

.mobileProductImageShape {
    grid-row: 1;
    grid-column: 1;
    width: auto;
    height: auto;
    width: 30rem;
}

.mobileProductImageShape svg {
    height: 100%;
    width: 100%;
    display: flex;
}

.mobileProductImage {
    grid-row: 1;
    grid-column: 1;
    height: 30rem;
    max-width: 12rem;
    display: flex;
}

.mobileProductImage img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: flex;
}


.sectionTextWrapper {
    padding-bottom: 2rem;
}

.applianceHeaderWrapperDiv1BtnWrapper button {
    width: max-content;
} */

@media (min-width: 430px) {

    /* ---------------------------------------------------- */
    /* MOBILE HEADER */
    /* ---------------------------------------------------- */
    /* .mobileProductImageShape {
        width: 40rem;
    }

    .mobileProductImage {
        height: 40rem;
        max-width: 20rem;
    } */
}


@media (min-width: 768px) {

    /* ---------------------------------------------------- */
    /* MOBILE HEADER */
    /* ---------------------------------------------------- */
    /* .mobileProductImageShape {
        width: 50rem;
    }

    .mobileProductImage {
        height: 50rem;
        max-width: 30rem;
    } */

}

@media (min-width:1024px) {}

@media (min-width: 1280px) {
    .mobileAppliancePageContainer {
        display: none;
    }

    .desktopAppliancePageContainer {
        display: block;
    }

    /* .applianceSectionContainer1 {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        background-color: var(--color-gray-06);
    }

    .applianceHeaderContainer {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column: 1/-1;
        padding: 0 8rem 4rem 8rem;

    }

  */

    /* .imageShapeGroupWrapper {
  
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto;
        justify-items: center;
        align-items: center;
    }

    .productBackgroundShapeContainer {
       
        grid-column: 1/-1;
        grid-row: 1;
        z-index: 0;
    }

    .productBackgroundShapeContainer svg {
        height: 60rem;
    }

    .productImageForShape {
    
        grid-column: 1/-1;
        grid-row: 1;
        z-index: 1;
    }

    .productImage {
      
        height: 50rem;
        padding: 4rem;
        max-width: 50rem;
        display: flex;
        justify-content: center;

    }

    .productImage img {
        height: 100%;
        width: 100%;

        object-fit: contain;

    } */

    /* TRANSFER */
    /* .applianceHeaderWrapperDiv1BtnWrapper {
        padding-top: 2rem;
    }

    .applianceSectionContainer2 {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        background-color: var(--color-gray-05);
    } */

    .applianceSpecsContainer {
        /* grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        padding: 4rem 8rem;
        column-gap: 4rem;
        border-top: 1px solid var(--color-gray-04); */
    }

    /* .applianceSpecsWrapper {
        grid-column: 2/12;
    }
     */
  


  
}

@media (min-width: 1920px) {

    /* ---------------------------------------------------- */
    /* MOBILE HEADER */
    /* ---------------------------------------------------- */
    /* .mobileProductImageShape {
        width: 60rem;
    }

    .mobileProductImage {
        height: 60rem;
        max-width: 40rem;
    } */

}




/* @media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} */