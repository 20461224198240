/* .heroImageGridContainer{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
} */
.heroContainer {
    /* grid-row: 1/-1;
    grid-column: 1/-1; */

    display: flex;
    flex-direction: column;
    background: url('/public/assets/image/backgrounds/main/studio-kitchen-02.webp');
        /* linear-gradient(rgba(0, 0, 0, 0.614), rgba(0, 0, 0, 0.564)), 
         linear-gradient(90deg, hsla(0, 0%, 100%, .3), rgba(0, 0, 0, .166)), */
        /* linear-gradient(rgba(0, 0, 0, 0.614), rgba(0, 0, 0, 0.564)),
        url('/public/assets/image/backgrounds/main/studio-kitchen-01.webp'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    height: 35rem;
    /* overflow: hidden; */
    /* background-attachment: fixed; */
    /* margin-top: 4rem;
    margin-bottom: 4rem; */
}
.heroImage{
    grid-row: 1/-1;
    grid-column: 1/-1;  
    z-index: 0;
}

.contentWrapper {
    padding: 0rem 2rem 2rem 2rem;
}
.heroWrapper{
    max-width: 60rem;
}

.longHeroSubtitle {
    display: none;
}

.heroHeader {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2rem;

}

.footer {
    padding-top: 2rem;
 
}

.title,
.subtitle,
.footer {
    color: white;
}



.exploreText {
    border-right: 1px solid var(--color-gray-01);
    position: sticky;
    padding-right: 1rem;
    color: var(--color-gray-01);
}



.desktopHomeSearchWrapper {
    display: none;
}




/* INTRO SECTION */
.introductionContainer {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    /* background-color: var(--color-gray-01); */
    /* display: grid;
    grid-template-rows: repeat(4,1fr);
    grid-template-columns: 1fr; */
}

.introTitle {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
}

.introTitleText {
    color: var(--color-gray-01);
}

.introTitleDescription {
    color: var(--color-gray-02);
}

.introDescriptionHeader {
    color: var(--color-gray-02);
    padding-top: 2rem;
    padding-bottom: 3rem;
}



.textWrapper {
    grid-row: 1;
    grid-column: 1;
    /* background-color: rgb(6, 57, 57); */
}

.rep2 {
    display: none;
}

.headImage1 {
    height: 10rem;
    width: auto;
    background-color: aqua;
}

.headImage2 {
    height: 20rem;
    width: auto;
    background-color: aqua;
}

.headImageGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aqua;
}

.imageWrapper {
    grid-row: 2;
    grid-column: 1;
    /* height: 30rem; */
    width: auto;
    display: flex;
    justify-content: center;
    /* justify-content: center;
    align-items: center; */
}

.imageWrapper img {
    height: 100%;
    width: auto;
    object-fit: contain;
}



.benefitCard {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.benefitCardText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
}

.longBenefitText {
    display: none;
    color: var(--color-gray-02);
}

.shortBenefitText {
    display: flex;
    color: var(--color-gray-02);
    padding-top: .5rem;
}
.benefitTitle a{
    color: var(--color-gray-01);
}

.longSubtitle {
    display: none;
    color: var(--color-gray-02);
}

.iconWrapper {
    height: 3rem;
    width: 3rem;
    display: flex;
    margin-bottom: auto;

}

.iconWrapper svg {
    height: 100%;
    width: auto;
    object-fit: contain;
}

.sectionTitle {
    color: var(--color-gray-01);
}

.sectionSubtitle {
    color: var(--color-gray-02);
}

/* QCARD */
.qCardWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* padding-top: 1rem; */
    padding: 1rem 0;
}

.qCard {
    /* width: 48.5rem;
    height: 17.7rem; */
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    border-radius: 2rem;
    padding: 2.25rem;
}

.qText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.qIcon {
    height: 4rem;
    width: 4rem;
}

.qIcon svg {
    height: 100%;
    width: auto;
    object-fit: contain;
}

.buttonWrapper {
    padding: 1rem 0 2rem 0;
}

.sectionHeader {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 1rem;
    gap: .5rem;
}





@media (min-width: 430px) {

    .imageWrapper {
        /* height: 35rem; */
        /* justify-content: flex-start; */
        /* justify-content: center; */
    }

    .headImage1 {
        height: 12rem;
    }

    .headImage2 {
        height: 24rem;
    }

    .introTitle {
        padding-bottom: 2rem;
    }

    .introDescriptionHeader {
        padding-top: 2rem;

        margin-bottom: 2rem;

    }

    .benefitCard {
        gap: 2rem;
        align-items: center;
        padding-bottom: 2rem;
    }

    .qCard {

        gap: 8rem;

    }
}

@media (min-width: 768px) {
    .heroContainer {
        height: 40rem;
    }

    .contentWrapper {
        padding: 0rem 4rem 2rem 4rem;
    }

    /* .imageWrapper {
        height: 40rem;
    } */
    .headImage1 {
        height: 20rem;
    }

    .headImage2 {
        height: 40rem;
    }

    .qIcon {
        height: 5rem;
        width: 5rem;
    }

}

@media (min-width: 1024px) {
    .heroContainer {
        height: 50rem;
    }
    .heroWrapper{
        min-width: 80rem;
    }
    .longBenefitText {
        display: flex;
    }

    .shortBenefitText {
        display: none;
    }

    .collarList {
        padding: 2rem 4rem;
    }

    .collarText a {
        font-size: 2rem;
    }

    .collarText a:hover {
        color: black;
        border-bottom: 2px solid red;
    }

    .introductionContainer {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, auto);
        align-items: center;
        /* background-color: var(--color-gray-01); */
        padding: 4rem 0;
        /* padding: 2rem 0; */
    }

    /* .textWrapper {
        grid-row: 1;
        grid-column: 1/7;
    } */

    .imageWrapper {
        grid-column: 6/-1;
        grid-row: 1/-1;
        justify-self: end;

        /* height: 40rem; */
        width: auto;
    }

    .rep1 {
        display: none;
    }

    .rep2 {
        display: flex;
    }

    .headImageGroup {
        flex-direction: column;
    }

    .headImage1 {
        height: 20rem;
    }

    .headImage2 {
        height: 40rem;
    }

    .textWrapper {
        grid-column: 1/6;
        grid-row: 1;
    }

    .introDescription {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        grid-row: 2;
        grid-column: 1/6;
    }

    .introDescriptionHeader {
        display: none;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }

    .introTitle {

        padding-bottom: 2rem;
    }

    .longSubtitle {
        display: flex;
        padding-bottom: 1rem;
    }

    .shortSubtitle {
        display: none;
    }

    .benefitCard {
        gap: 2rem;
        align-items: center;

    }

    .benefitCardText {
        gap: 1rem;
    }

    .iconWrapper {
        /* height: 5.7rem;
        width: 5.7rem;
        background-color: var(--color-gray-06);
        border: 4px solid var(--color-gray-06);
        border-radius: 100rem;
        padding: 1rem; */
    }

    /* QCARD */

    .qCard {
        /* width: 48.5rem;
        height: 17.7rem; */

        gap: 4rem;
        border-radius: 2rem;
        padding: 2rem 3rem;
    }

    .qCardWrapper {
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }

    .qIcon {
        height: 6rem;
        width: 6rem;
        align-self: flex-start;
    }

    /* .buttonWrapper button{
        max-width: max-content;
    }
    
    .benefitsList{
        padding: 2rem 0;
    }
    .benefitCard{
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        padding-bottom: 2rem;
    }
    .benefitCardText{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .sectionHeader{
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        gap: .5rem;
    }
    
    .qCardWrapper{
        display: flex;
      flex-direction: column;
    }
    .qCard{
    width: 48.5rem;
    height: 17.7rem;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6rem;
    border-radius: 4rem;
    padding: 2rem 3rem;
    }
    .qText{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    
    .qCardWrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    */

    .mobileHomeSearchWrapper {
        display: none;
    }

    .desktopHomeSearchInputWrapper {
        width: 60rem;
    }

    .desktopHomeSearchWrapper {
        display: block;
    }
}

@media(min-width:1280px) {
    .imageWrapper {
        height: 50rem;
    }

    .qIcon {
        height: 7rem;
        width: 7rem;
        align-self: center;
    }

    .qCard {
        width: 48.5rem;
        height: 17.7rem;
    }




}

@media(min-width:1440px) {
    .introductionContainer {

        padding: 4rem 0;

    }

    .rep1 {
        display: flex;
    }

    .rep2 {
        display: none;
    }

    .headImageGroup {
        flex-direction: row;
    }

    .headImage1 {
        height: 25rem;
    }

    .headImage2 {
        height: 50rem;
    }


}

@media (min-width: 1920px) {
    .heroContainer {
        height: 65rem;
        padding: 0 4rem;
    }

    .collarList {
        padding: 1.5rem 0;
        /* justify-content: space-between; */
    }
    .sectionHeader{
        padding-top: 4rem;
    }
    .qCardWrapper{
        padding-bottom: 4rem;
    }
    .headImage1 {
        height: 27rem;
    }

    .headImage2 {
        height: 54rem;
    }

    .introductionContainer {

        padding: 8rem 0rem;

    }
.contentWrapper{
    padding: 0;
}

}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/