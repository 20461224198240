.routeLinkListItem a{
    transform: scale(1);
    transition: transform 0.1s ease, color 0.1s ease;
   color: var(--color-gray-02);
    display: inline-flex;
    border-bottom: 2px solid transparent;

}
.routeLinkListItem a:hover{
    transform: scale(1.0);
    color: var(--color-gray-01);
    border-bottom: 2px solid var(--color-red-03);
}
