

.scrollBarContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.scrollBarContainer {
    padding-bottom: 2rem;
}
.headerContainer{
    display: flex;
    flex-direction: column-reverse;
    gap: .5rem;
    padding-bottom: 1rem;
    /* NEW */
    /* align-items: center; */
}
.headerTitle{
    color: var(--color-gray-01);
}
.headerCountWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-left: auto; */
    gap: 2rem;

}
.countTitle{
    color: var(--color-gray-02);
}
/* @media (min-width: 1440px) { */
@media (min-width: 1920px) {
    .headerContainer{
        flex-direction: row;
    }
    .headerCountWrapper{   
        margin-left: auto;
    }
} 





/* .countBubble {
  
    display: flex;
    width: 50px;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100rem;
    border: 1px solid var(--color-gray-04);
    font-size: 1.8rem;
    font-family: var(--lg-ei-text-regular);
    transition: border-color 0.3s;
    position: relative;
    overflow: hidden;
} */

/* @media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} */