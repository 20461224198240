.searchPreviewWithResults {
    position: relative;
    /* height: 100rem; */
    height: 100vh;
    /* height: 90rem; */
    overflow-y: scroll;
    background-color: var(--color-gray-07);
    /* background-color: var(--color-gray-06); */
    scrollbar-color: var(--color-gray-04) var(--color-gray-05);
    scrollbar-width: thin;
    scroll-behavior: smooth;
    padding-bottom: 4rem;
}

.searchIconWrapper svg {
    height: 1.6rem;
    width: 1.6rem;
    object-fit: contain;
}

.input {
    width: 100%;
    border: none;
    font-size: 1.6rem;
    background-color: white;
    color: var(--color-gray-02);
}

.input:focus {
    outline: none;
}

/* --------------------------------------------------------------------------------------- */
/* SEARCH PREVIEW */
/* --------------------------------------------------------------------------------------- */
.searchPreviewDesktopMainContainer {
    background-color: var(--color-gray-06);
    padding: 0 .85rem 0 1rem;
    position: absolute;
    border-bottom-left-radius: 2.6rem;
    border-bottom-right-radius: 2.6rem;
    border-left: 1px solid var(--color-gray-04);
    border-right: 1px solid var(--color-gray-04);
    z-index: 2;
    /* border-bottom: 1px solid var(--color-gray-04); */
}

.desktopSearchPreviewWithResultsWrapper {
    position: relative;

    width: 38rem;
    height: 62rem;


}

.desktopSearchPreviewWithResultsInnerDiv {
    /* position: absolute; */
    height: 60.5rem;
    overflow-y: scroll;
    background-color: var(--color-gray-06);
    /* scroll-snap-type: y proximity; */

}

.desktopSearchPreviewWithResultsInnerDiv>div {
    /* scroll-snap-align: start; */

}

/* For WebKit-based browsers */
.desktopSearchPreviewWithResultsInnerDiv::-webkit-scrollbar {
    width: 1rem;
}

.desktopSearchPreviewWithResultsInnerDiv::-webkit-scrollbar-track {
    background: var(--color-gray-06);
}

.desktopSearchPreviewWithResultsInnerDiv::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-04);
    border-radius: 10px;
    border: 1px solid var(--color-gray-05);
}

.desktopSearchPreviewWithResultsInnerDiv::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-red-02);
}

.desktopSearchPreviewWithResultsInnerDiv:last-child {
    padding-bottom: .5rem;
}

.searchPreview {
    padding: 0 2rem;
}

.searchOptionButtonWrapper {
    /* background-color: aqua; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: .5rem;

}

.searchOptionButtonWrapper button {
    width: max-content;
}

.searchPreviewSectionTitleWraper {
    /* border-top: 1px solid var(--color-gray-03); */
    /* margin-top: 2rem; */
    /* margin-top: 5rem; */
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: var(--color-gray-02);
}

/* .desktopSearchPreviewSpacer{
    background-color: aquamarine;
    padding: 2rem;
    position: relative;
    height: auto;
} */