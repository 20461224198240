.mainLabelContainer {
/* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    min-height: 10rem;
    margin-bottom: 1rem;
}

.labelHeaderWrapper {
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.labelText {
    /* font-size: 1.6rem;
    color: var(--color-gray-01); */
}