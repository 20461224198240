.collar{
    white-space: nowrap;
}

.collarList{
    display: flex;
    flex-direction: row-reverse;
    gap: 2rem;
    justify-content: space-between;

    overflow-x: auto;
    overflow-y: hidden;
    padding: 2rem;
}
.collarText a {
    font-size: 1.8rem;
    color: var(--color-gray-02);
    border-bottom: 2px solid transparent;
}


.collarList::-webkit-scrollbar {
    width: 1rem;
}

.collarList::-webkit-scrollbar-track {
    background-color: var(--color-gray-05);
}

.collarList::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-06);
    border-radius: 2px;
    border: 1px solid var(--color-gray-04);
}

.collarList::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-04);
}


