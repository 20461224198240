
.specificationComponentContainer {
    /* grid-column: 2/12; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    justify-items: flex-start;
    gap: 4rem;
    padding: 0 2rem;
}

.specListWrapper {

    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.specListWrapper {
    padding-top: 2rem;

}

.speclistItem {
    /* list-style: ; */

}

.specListTitle {
    color: var(--color-red-02);
    font-family: var(--text-semibold);
}

.printSpecListTitle{
    display: none;
}
@media (min-width: 768px) {
    .specificationComponentContainer {
        padding: 0 4rem;
    }
}
@media (min-width: 1920px) {
    .specificationComponentContainer {
        padding: 0;
    }
}
@media print {
    .specificationComponentContainer {
        /* grid-column: 2/12; */
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
        align-items:flex-start;
        justify-items:stretch;
        gap: 2rem;
    }
    .specListTitle {
        display: none;
    }
    .printUl{
        display: flex;
        flex-direction: column;
        gap: .5rem;
        /* font-size: 1.4rem; */
    }
    .printLi p{
        font-size: 1.2rem;
    
    }
    .printSpecListTitle{
        font-size: 1.4rem;
        padding-bottom: .3rem;
        font-family: var(--text-semibold);
        color: var(--color-red-02);  
        display: flex; 
       
    }
 
}
