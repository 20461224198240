button {
    width: 100%;
    padding: 1.2rem 1.5rem;
    font-family: var(--text-semibold);
    font-size: 1.6rem;
    white-space: nowrap;
    text-align: center;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon{
    height: 2.2rem;
    width: 2.2rem;
    display: flex;
    padding-left: .5rem;
}
.icon svg{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

button:hover {
    cursor: pointer;
}

.primaryAction {
    background-color: var(--color-red-03);
    color: white;
    border-radius: 100rem;
    border: 1px solid var(--color-red-03);
}

.secondary {
    background-color: white;
    color: var(--color-gray-01);
    border: .1rem solid var(--color-gray-03);
    border-radius: 100rem;
}

.primary {
    background-color: black;
    color: white;

    border-radius: 100rem;
    border: .1rem solid black;
}

.printDefault {
    background-size: 400% 400%;
    background-image: linear-gradient(100deg, red, black, var(--color-red-03));
    animation: gradientAnimation 10s ease infinite;
    color: white;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100rem;
    border: 1px solid var(--color-red-03);
}

.tertiaryGray {
    background-color: var(--color-gray-04);
    border: none;
    color: black;
    display: flex;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-03);
    width: max-content;
}

.tertiaryGray a {
    text-decoration: none;
    font-size: var(--action-btn-font-size);
    font-family: var(--action-btn-font-family);
    color: var(--color-gray-01);
}

.clearFormField {
    background-color: var(--color-gray-06);
    color: var(--color-gray-03);
    border-radius: 1rem;
    border: 1px solid var(--color-gray-04);
    text-align: left;
}


@media (min-width: 1024px) {
    button {
        padding: 1.5rem 2rem;
    }
}


@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/