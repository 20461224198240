.contentWrapper {
    padding: 0rem 2rem 2rem 2rem;
}

.sectionHeader {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 1rem;
    gap: 1rem;
    /* background: red; */
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* padding-top: 1rem; */
    padding: 1rem 0;
}

@media (min-width: 768px) {
    .contentWrapper {
        padding: 0rem 4rem 2rem 4rem;
    }
}
@media (min-width: 1024px) {
    .contentWrapper {
        padding: 0rem 4rem 2rem 4rem;
    }
    /* .cardWrapper{
        display: flex;
        flex-direction: row;
        gap: 2rem;
    } */
}
@media (min-width: 1280px) {
    
    .cardWrapper{
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }
}
@media (min-width: 1920px) {
    .sectionHeader {
        padding-top: 3rem;
    }

    .cardWrapper {
        padding-bottom: 4rem;
    }

    .contentWrapper {
        padding: 0;
    }
}