.rowContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-gray-05);
    margin-bottom: 2rem;
    width: 100%;
    justify-content: space-between;
}
.dimensionText{
    font-size: 1.6rem;
    color: var(--color-gray-03);
}
 .metricText{
    font-size: 1.8rem;

     color: var(--color-gray-01);  
    /* font-family: var(--text-semibold); */
}
.bubbleDimensionGroup{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
   
}