.portalTopbarNavigationContainer {
  display: flex;
  align-items: center;
  padding: 2rem;
  background-color: var(--color-gray-06);
  border-bottom: 1px solid var(--color-gray-04);
 }
 .mobileNavDropdownMenuWrapper{
  padding-left: 2rem;
 }
 .logoWrapper{
    margin-right: auto;
 }
.mobileMenuHeader{
   padding-left: 2rem;
   padding-right: 2rem;
 padding-bottom: 4rem;
}
.portalBurgerWrapper{
   background-color: var(--color-gray-07);
   padding: 1rem;
   border-radius: 10rem;
  
}
 .headerIconTextGroup{
    display: flex;
    flex-direction: row;
    gap: 1rem;
 }
 .iconsWrapper{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
 }
 /* NAV DIRECTIONAL ARROWS */
 .icon svg{
    height: 2.4rem;
    width: 2.4rem;
    display: inline-flex;
 }

 .userAccountWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    /* background-color: var(--color-gray-07);
    padding: 1rem 2rem;
    border: 1px solid var(--color-gray-05); */
    /* border-radius: 4rem; */
    color: var(--color-gray-01);
 }
.userDetails{
   display: flex;
   flex-direction: row;
   gap: .5rem;
}
 .headerText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* gap: 2rem; */
    /* margin-bottom: 2rem; */
}
.portalMobileNavBackIconWrapper{
   padding-top: 2rem;
   display: inline-flex;
   padding-left: 2rem;
   padding-right: 2rem;
}
 .mobileMenuBackIconWrapper{
   padding-top: 2rem;
   display: inline-flex;
   /* padding-left: 2rem;
   padding-right: 2rem; */
}
.logoutIcon svg{
  height: 2.4rem;
  width: 2.4rem;
   display: inline-flex;
}
.logoutIcon svg:hover{
   cursor: pointer;
}
#firstHeader {
    /* border-top: 1px solid var(--color-gray-04); */
    padding-top: 4rem;
}
.mobileNavOptionsList a {
    color: black;
    font-size: 1.8rem;
}

.mobileNavOptionsList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 1rem;
}

.mobileNavOptionsList li {
    list-style: none;
}

.logoutWrapper{
   display: none;
}
.mobileDropdownContent{
   padding-top: 2rem;
 
}
/* .portalMobileNavDropdownMenuWrapper{} */
/* .logoutDivider{
   display: none;
} */
.topNavUserAccount{
   display: none;

 }
@media (min-width: 768px) {
   .icon:hover{
     cursor: pointer;
   }
}
@media (min-width: 1280px) {
   .accountIcon svg{
      height: 3.5rem;
      width: 3.5rem;
      display: inline-flex;
      margin-right: 2rem;
   }
   .topNavUserAccount{
      display: flex;
      /* background-color: rgb(76, 0, 255); */
    }
   /* .topNavUserAccount{
     display: none;
   } */
}
@media (min-width: 1440px) {
    .portalBurgerWrapper{
        display: none;
    }
    .icon svg{
        height: 3.5rem;
        width: 3.5rem;
        display: flex;
     }
     .userDetails{
        display: flex;
        flex-direction: row;
        gap: .5rem;
     }
     .userAccountWrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
     }
     .logoutWrapper{
   
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
   }
   .logoutIconWrapper{
      height: 2rem;
      width: 2rem;
   }
   .logoutIconWrapper svg {
      height: 100%;
      width: 100%;
      object-fit: contain;
   }
   .logoutWrapper:hover{
      cursor: pointer;
   }
  
}
/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/