.mainContainer {

    display: grid;
    /* height: 100vh; */
    /* flex-direction: column; */
    grid-template-columns: 1fr;
    grid-template-rows: 5rem repeat(2, auto) 100% auto;
    /* grid-template-rows: auto 90vh auto auto; */

    /* justify-content: center;
    align-items: center; */
    background: var(--color-gray-05);
}

.logo {
    grid-row: 2;
    height: 5rem;
    /* height: 10rem; */
    width: auto;
    /* margin-bottom: 2rem; */
}

.logo img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.body {
    grid-row: 3;
    height: 100%;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerText {
    text-align: center;
    padding-bottom: 1rem;
}

.bodyBreak {
    grid-row: 4;
    background-color: var(--color-gray-05);
}

.form {
    display: flex;
    width: 30rem;
    justify-self: center;
    justify-content: center;
}
.form > form{
    width: 100%;
}
.footer {
    padding: 1.5rem 0;
    grid-row: 5;
    background-color: var(--color-gray-06);
    border-top: 1px solid var(--color-gray-04);

}

.footerCTAWrapper {
    padding-top: 2rem;
    text-align: center;
}

.footerLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}
.footerLinks > a{
    text-decoration: underline;
    color: var(--color-gray-03);
}

.linkText > a{
    color: var(--color-gray-03);
}
.linkText > a:hover{
    text-decoration: underline;
    color: var(--color-red-03);
}
.footerLinks a:hover{
    color: var(--color-gray-01);
}

@media (min-width: 460px) {
    .form {
        max-width: 40rem;
    }
}

@media (min-width: 768px) {
    .form {
        width: 60rem;
    }
}

@media (min-width: 1280px) {
    .body{
        background-color: var(--color-gray-06);
        border: 1px solid var(--color-gray-05);
        /* padding: 2rem 2rem 4rem 2rem; */
        border-radius: 2rem;
        width: max-content;
        height: max-content;
        margin: 5rem auto 80rem auto;
        padding: 5rem 7rem;
        
    }
    .mainContainer {
        grid-template-rows: repeat(2, auto) 1fr auto;
    }
    
  
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/