


/* -------------------------------------------------------- */
/* SEARCH PREVIEW NAV MOBILE */
/* -------------------------------------------------------- */
.searchPreviewCardContainerM {
    align-items: center;
    height: auto;
    gap: 1.7rem;
    padding: 1rem;
}

.searchPreviewCardWrapperM {
    display: grid;
    grid-template-columns: .5fr 1fr;
    grid-template-rows: auto auto;
    background-color: white;
    /* background-color: rgb(143, 12, 12); */
    padding: 2.25rem;
    gap: 2rem;
    border-radius: 4rem;
    border: 1px solid var(--color-gray-04);

    max-width: 70rem;
    margin: 0 auto;
}

.searchPreviewCardImageWrapperM {
    grid-column: 1;
    grid-row: 1;
}

.searchPreviewCardImageM {
    width: 100%;
    height: 7rem;
    object-fit: contain;
    /* background-color: white;
    padding: .5rem; */
}


.searchPreviewCardTextWrapperM {
    grid-column: 2;
    grid-row: 1;
    align-self: center;
}

.searchPreviewCardTextM {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.searchPreviewTitle{
    color: var(--color-gray-03);
}
.searchPreviewSubtitle{
    color: var(--color-gray-01);
}


.searchPreviewCardTextM svg {
    height: 1rem;
    display: flex;
}

.searchPreviewButtonWrapperM {
    grid-column: 1/-1;
    grid-row: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
}

.searchPreviewButtonWrapperM button {
    width: 100%;

}

.searchPreviewClampedSubtitleM {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


@media (min-width: 430px) {

    /* -------------------------------------------------------- */
    /* SEARCH PREVIEW NAV MOBILE */
    /* -------------------------------------------------------- */
    .searchPreviewCardWrapperM {
        gap: 2rem;
      padding: 3rem;
    }
    
    .searchPreviewCardContainerM {
        padding: 1rem 2rem;
       
    }

    .searchPreviewCardImageM {
        height: 10rem;
    }

    .searchPreviewClampedSubtitleM {
        -webkit-line-clamp: 3;
    }

    .searchPreviewButtonWrapperM {
        gap: 3rem;
    }

    /* -------------------------------------------------------- */
    /* SEARCH PREVIEW NAV MOBILE */
    /* -------------------------------------------------------- */
}


@media (min-width: 768px) {

    /* -------------------------------------------------------- */
    /* SEARCH PREVIEW NAV MOBILE */
    /* -------------------------------------------------------- */
    .searchPreviewCardImageM {
        height: 14rem;
    }
    .searchPreviewCardWrapperM {
      
        padding: 3.25rem;
       
    }
    .searchPreviewClampedSubtitleM {
        -webkit-line-clamp: 4;
    }

    /* -------------------------------------------------------- */
    /* SEARCH PREVIEW NAV MOBILE */
    /* -------------------------------------------------------- */
}

@media (min-width: 834px) {
    .searchPreviewCardWrapperM {
        padding: 4.25rem;
    }
}

@media (min-width: 1920px) {
  
}
.searchResultsPreviewCardContainer {
    /* background-color: red; */
    display: grid;
    grid-template-columns: .23fr 1fr auto;
    grid-template-rows: auto;
    align-items: center;
    height: 10rem;
    gap: 1.7rem;
    background-color: white;
    border-radius: 2.6rem;
    margin-bottom: 1rem;
    padding: 2rem;
}
.searchResultsPreviewCardContainer button {
   padding: 1rem;
}

.searchResultsPreviewCardContainer:first-child {
    margin-top: 1rem;
    /* border-bottom: 1px solid var(--color-gray-04); */
}

.searchResultsPreviewCardImageWrapper {}

.searchResultsPreviewCardImageWrapper img {
    width: 100%;
    height: 5rem;
    object-fit: contain;
}
.searchResultsPreviewCardText {
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: .4rem;
    color:var(--color-gray-01);
}

.clampedSubtitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}




/* @media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} */