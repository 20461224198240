/* --------------------------------------------------------------------------------------- */
/* SEARCH INPUT */
/* --------------------------------------------------------------------------------------- */


/* --------------------------------------------------------------------------------------- */
/* SEARCH PREVIEW */
/* --------------------------------------------------------------------------------------- */
.searchPreviewMainContainer{
  background-color: var(--color-gray-06);
    padding: 0 2rem 0 0;
    position: absolute;
     border-bottom-left-radius: 2.6rem;
    border-bottom-right-radius: 2.6rem;
    z-index: 1;
    /* height: auto; */
    /* height: 82rem; */
}
.searchPreviewWithResultsWrapper{
position: relative;
/* position: absolute; */
width: 58rem;
height: 49rem;
 /* border-bottom-left-radius: 2.6rem;
    border-bottom-right-radius: 2.6rem; */
    /* height: 52rem; */
    /* background-color: aqua; */
}

.searchPreviewWithResultsInnerDiv{
    position: relative;
    /* height: 50.5rem; */
    /* height: 80rem; */
    /* height: auto; */
    height: 48rem;
    overflow-y: scroll;
    padding: 1rem;
    /* background-color: rebeccapurple; */
}
/* For WebKit-based browsers */
.searchPreviewWithResultsInnerDiv::-webkit-scrollbar {
    width: 1.5rem;
  }
  
  .searchPreviewWithResultsInnerDiv::-webkit-scrollbar-track {
    background: var(--color-gray-06);
  }
  
  .searchPreviewWithResultsInnerDiv::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-04);
    border-radius: 10rem;
    border: 1px solid var(--color-gray-05);
  }
  
  .searchPreviewWithResultsInnerDiv::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-red-02);
  }
.desktopSearchPreviewWithResultsInnerDiv:last-child{
    padding-bottom: .5rem;
}
/* .desktopSearchPreviewSpacer{
    background-color: aquamarine;
    padding: 2rem;
    position: relative;
    height: auto;
} */