.countBubble {
    display: flex;
    width: 4.2rem;
    height: 4.2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100rem;
    /* border: 1px solid var(--color-red-02); */
    font-size: 1.4rem;
   
    /* transition: border-color 0.3s;
    position: relative; */
    /* overflow: hidden; */
}
@media (min-width: 1024px) {
    .countBubble {
        width: 5rem;
        height: 5rem;
        font-size: 1.6rem;
    }
}

