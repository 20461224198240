.list li{
    list-style: none;
}
.heroContainer {
    height: auto;
    padding: 3rem 2rem;
}

.heroWrapper {
    display: flex;
    flex-direction: column;
    /* gap: 4rem; */
}

.heroTextWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   /* padding-bottom: 2rem; */
}
.heroTitle{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--color-gray-01);
}

.heroDescription {
    display: none;
}
.heroDescriptionText{
    color: var(--color-gray-03);
}
.mobileImageGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    justify-content: center;
    padding: 2rem 0 3rem 0 ;
}

.image1 {
    height: 10rem;
    width: auto;
    grid-column: 2/3;
    grid-row: 1;

}

.image2 {
    height: 20rem;
    width: auto;
    grid-column: 3;
    grid-row: 1;
}

.image1 img,
.image2 img {
    height: 100%;
    width: auto;
    object-fit: contain;
}

.image3 {
    display: none;
}

.dStepUpLinksContainer {
    display: none;
}

.mStepUpLinksContainer {
    display: block;
}
.mStepUpLinksContainer li {
    list-style: none;
}

.stepUpChartCalloutText a {
    color: var(--color-gray-03);
    text-decoration:underline;
    font-family: var(--text-semibold);
}
.mStepUpLinksContainer a{
    color: white;
}

@media(min-width: 430px) {
    /* .image1 {
        height: 15rem;
    }

    .image2 {
        height: 30rem;
    } */
    .image1 {
        height: 12rem;
    }

    .image2 {
        height: 24rem;
    }
}

@media(min-width: 640px) {
  
    /* .mobileImageGroup {
        justify-content: right;
    } */
   

    /* .image1 {
        height: 20rem;
    }

    .image2 {
        height: 40rem;
    } */

 /* .image1 {
        height: 15rem;
    }

    .image2 {
        height: 30rem;
    } */
}

@media (min-width: 640px) {

    .heroWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4rem;
        /* padding-bottom: 1rem; */
    }
    .heroContainer {
        padding: 3rem 2rem ;
        /* padding: 3rem 4rem ; */
        /* padding-top: 3rem; */
    }
    .image1 {
        height: 9rem;
    }

    .image2 {
        height: 18rem;
    }
    .mStepUpLinksContainer {
        display: flex;
        padding: 2rem 0 0 0;
    }
}

@media (min-width: 768px) {
    .heroContainer {
        padding: 3rem 4rem ;
    }
    .mStepUpLinksContainer {
        display: flex;
        padding: 0 2rem 0 0;
    }
}

@media (min-width: 834px) {
    
    .heroWrapper {
        /* gap: 6rem; */
        gap: 2rem;
    }
    .image1 {
        height: 14rem;
    }

    .image2 {
        height: 28rem;
    }
/* @media (min-width: 1024px) { */

    /* .heroContainer{
        margin: 4rem 0 4rem 0 ;
    } */
    /* .heroTextWrapper {
        width: 80%;
    } */

    /* .image1 {
        height: 22rem;
    }

    .image2 {
        height: 44rem;
    } */
}
@media (min-width: 1024px) {
/* @media (min-width: 1280px) { */
.heroTextWrapper {
    width: 80%;
}
    .mobileImageGroup {
        display: none;
    }

    .mStepUpLinksContainer {
        display: none;
    }

    .dStepUpLinksContainer {
        display: block;
        margin-top: 4rem;
    }
    .dStepUpChartCalloutText{
        /* background-color: aqua;  
        padding-right: 4rem; */
    }
    .heroDescriptionText{
        color: white;
    }
    .stepUpChartLinkGroupWrapper a {
        color: white;
        font-family: var(--text-semibold);
    }

    .dStepUpChartCalloutIcon{
        height: 2.4rem;
        width: 2.4rem;
        /* background-color: aquamarine; */
       
    }
    .dStepUpChartCalloutIcon svg{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .stepUpChartLinkGroupWrapper{
        /* background-color: aqua; */
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .stepUpChartInnerLinkDivWrapper{
        display: flex;
        flex-direction: row;
        /* padding-left: .8rem; */
        gap: 1rem;
        align-items: center;
    }

    .heroContainer {
        padding: 0;
        margin: 0 4rem;
        
        /* margin-bottom: 4rem; */
    }

    .heroWrapper {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        gap: 6rem;
    }

    .heroTextWrapper {
        /* grid-column: 1/8; */
        /* grid-column: 1/9; */
        grid-column: 1/10;
          grid-row: 1;
          padding: 0 0rem 0 4rem;
        /* grid-column: 2/9; */
        /* grid-column: 2/7; */
      
        /* padding: 0 0 0 4rem; */
    }

    .heroImageWrapper {
        grid-column: 2/-1;
        margin-left: auto;
        grid-row: 1;
    }

    .image3 {
        display: block;
        max-height: 80rem;
        height: 40rem;
        width: 52rem;
        /* height: 45rem; */
        /* width: auto; */
    }

    .image3 img {
        height: 100%;
        width: auto;
        object-fit: contain;
    }

    .heroTextWrapper,
    .heroTitle,
    .heroDescription {

        color: white;
    }
}
@media (min-width: 1280px) {
    
    .heroTextWrapper {
        /* grid-column: 1/8; */
        grid-column: 1/9;
          grid-row: 1;
          padding: 0 0 0 4rem;
        /* grid-column: 2/9; */
        /* grid-column: 2/7; */
      
        /* padding: 0 0 0 4rem; */
    }
    .image3 {
        height: 45rem;
        width: auto;
    }
}
@media (min-width: 1440px) {
    
    .dStepUpChartCalloutText a {
        color: white;
        font-family: var(--text-semibold);
        text-decoration: underline;
    }

    /* .image3 {
        height: 55rem;
    } */
}

@media (min-width: 1920px) {
      .heroContainer{
        margin: 4rem 0;
    }
    .dStepUpLinksContainer {

        margin-top: 8rem;
    }

    .image3 {
        height: 60rem;
    }
}



/* @media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} */