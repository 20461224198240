.relatedProductContainer {
    border: 1px solid var(--color-gray-04);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
     width: 30rem;
    height: auto;
    /* width: 35rem;
    height: 25rem; */
    background-color: white;
    border-radius: 26px;
    gap: 1rem;
    /* padding: .7rem 1rem; */
    padding: 3rem 2rem 2rem 2rem;
}

.relatedProductContainer a {
    text-decoration: none;
}
.realtedProductImageWrapper {

    height: 20rem;
   /* width: auto; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.relatedProductImage {
    height: 100%;
    width: 100%;
     object-fit: contain;
}
.relatedProductTextWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.productText {
color: var(--color-gray-01);
}

.clampedSubtitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.clampedSubtitleLong {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.buttonWrapper {
    grid-column: 1/-1;
    grid-row: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding-top: 1rem;
}
.buttonWrapper button {
    width: 100%;
}

@media (min-width: 430px) {
    .buttonWrapper {
        gap: 2rem;
    }
}