.builderCardContainer {
    background-color: var(--color-gray-07);
    /* background-color: white; */
    margin: 0 2rem 2rem 2rem;
    border-radius: 2rem;
    padding: 4rem;

    border: 1px solid var(--color-gray-04);
}



.builderCardHeaderTextWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
}

.headerText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.headerText  a{
    color: var(--color-gray-03);
}
.categoryTextWrapper {
    color: var(--color-gray-03);
    padding-bottom: 1rem;
}

.titleTextWrapper {
    color: var(--color-gray-03);
}

.subtitleTextWrapper {
    color: var(--color-gray-01);
    font-family: var(--text-semibold);
}

.clampedSubtitle {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.builderCardBodyWrapper {
    padding: 1rem 0 1rem 0;
}

.builderCardImageButtonGroupItem1 {}

.builderCardImageButtonGroup {}

.builderCardImage {
    height: 20rem;
    width: auto;
    padding: 2rem 0;
}

.builderCardImage img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
.builderCardButtonWrapper{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.builderCardButton {
    width: 100%;
}

.builderCardSpecsWrapperItem2 {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}


.productListBannerCardImage img {
    height: 10rem;
    width: auto;
    object-fit: contain;
}



.specsDropdownHeader {

    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-04);
    padding-bottom: 1rem;
    justify-content: space-between;
    /* font-family: var(--text-semibold); */
}
.toggleIcon{
    background-color: var(--color-gray-05);
    background-color: white;
    height: 5rem;
    width: 5rem;
    display: flex;
    padding: 1.5rem;
    border-radius: 40rem;
    justify-content: center;
    border: 1px solid var(--color-gray-04);
}
.toggleIcon svg{
    height: 100%;
    /* width: 100%; */
    object-fit: contain;
}
.toggleIcon:hover{
    cursor: pointer;
}
.toggleText:hover{
    cursor: pointer;
}

.toggleText{
    color: var(--color-gray-02);
}
.specsDropdownBody {}

.specListItem {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
}
.listItem{
   
    color: var(--color-gray-03);
}

@media (min-width: 430px) {
    

    .builderCardImage {
        height: 25rem;
        padding: 4rem 0;
    }




}

@media (min-width: 768px) {
    .builderCardContainer {
        margin: 0 4rem 2rem 4rem;
    }
    .builderCardImage {
        height: 30rem;
    }
   
    /* .builderCardButton {
        padding: 0 12rem;
     } */
}

@media (min-width: 834px) {
    .builderCardHeaderTextWrapper {
        /* gap: 8rem; */
        gap: 0;
      
        grid-template-columns: 1fr auto;
        grid-template-rows: repeat(2,auto);
    }

    .headerText{
        grid-row: 2;
    }
    .removeCardButton {
        grid-row: 1;
        justify-self: flex-end;

    }

    .removeCardButton span:hover {
        cursor: pointer;
    }

    .builderCardBodyWrapper {
        display: grid;
        grid-template-columns: .3fr 1fr;
        /* grid-template-columns: .3fr .7fr; */
        /* grid-template-columns: .5fr .5fr; */
        gap: 8rem;
    
    }

    .builderCardImageButtonGroupItem1 {
        grid-column: 1;
    }

    .builderCardSpecsWrapperItem2 {
        grid-column: 2;
        align-self: center;
    }

    .specsDropdownHeader svg:hover {
        cursor: pointer;
    }
}

@media (min-width: 1024px) {}

@media (min-width: 1280px) {
    .builderCardContainer {
        padding: 4rem 8rem;
    }
}

@media (min-width: 1440px) {
   
}

@media (min-width: 1920px) {
     .builderCardContainer {
        margin: 0 0 2rem 0;
    }
}