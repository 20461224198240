.heroContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 2rem;
    gap: 2rem;
    /* background-size: 400% 400%;
    background-image: linear-gradient(45deg, #f78b8b, #ffb5b5, rgb(255, 224, 224), var(--color-gray-07), #ffb5b5, #f78b8b);
    animation: gradientAnimation 15s ease infinite; */
}

.heroTextWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.heroTitle {
    color: var(--color-gray-01);
}

.heroSubtitle {
    color: var(--color-gray-02);
}

.heroDescriptionShort{
    display: flex;
}
.heroDescriptionLong{
    display: none;
}
.image0 {
    height: auto;
    width: auto;
    max-width: 50rem;
}

.image0 img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.image1,
.image2,
.image3,
.image4 {
    display: none;
}

.heroButtonWrapper {
    display: none;
}

.heroButtonWrapperMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 3rem 2rem;
}

.heroButtonWrapperMobile button {
    width: 100%;
}

.categoriesComponentWrapper {
    background-color: var(--color-gray-05);
    padding: 3rem 0;
    /* padding: 4rem; */
}




@media (min-width: 768px) {
    .heroContainer {

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, auto);
        justify-items: center;
    }

    .heroTextWrapper {
        grid-column: 1/11;
        grid-row: 1;
    }

    .heroImageWrapper {
        grid-column: 1/-1;
        grid-row: 2;
    }

    .image0 {
        max-width: 60rem;
    }

    .heroButtonWrapperMobile {
        padding: 2rem 2rem 4rem 2rem;

    }

}


/* @media(min-width:1080px) {
    .image0 {
        max-width: 60rem;
    }
} */

@media(min-width:1080px) {

    .heroContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        padding: 3rem 4rem 3rem 4rem;
        align-items: center;
        gap: 0;
    }

    .heroTextWrapper {
        grid-column: 1;
        grid-row: 1;
    }
    .heroDescriptionShort{
        display: none;
    }

    .heroDescriptionLong {
        display: flex;
    }

    .image0 {
        display: none;
    }

    .heroImageWrapper {
        grid-column: 2;
        grid-row: 1;

        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-template-rows: repeat(4, auto);
        justify-items: end;
        gap: 0 2rem
    }

    .image1 {
        display: flex;
        height: auto;
        width: 18rem;
        grid-column: 1/3;
        grid-row: 3;
    }

    .image2 {
        display: flex;
        height: 32rem;

        width: auto;
        grid-column: 3;
        grid-row: 2/-1;
    }

    .image3 {
        display: flex;
        height: auto;
        width: 32rem;
        height: 20rem;
        grid-column: 2/4;
        grid-row: 1;
    }

    .image4 {
        display: flex;

        height: 57rem;
        width: 23rem;
        grid-column: 4;
        grid-row: 1/-1;
    }

    .image1 img,
    .image2 img,
    .image3 img,
    .image4 img {
        display: flex;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .heroButtonWrapperMobile {
        display: none;
    }

    .heroButtonWrapper {
        display: flex;
        padding-top: 1rem;
    }

    .heroButtonWrapper button {
        width: max-content;
    }

    .categoriesComponentWrapper {
        padding: 4rem 0;

    }
}

@media (min-width: 1920px) {

    .image1 {
        width: 27rem;
    }

    .image2 {
        height: 36rem;
    }

    .image3 {
        width: 40rem;
        height: 20rem;
    }

    .image4 {
        height: 57rem;
        width: 30rem;
    }

    .heroContainer {
        padding: 3rem 0rem 4rem 0;
    }
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/