.applianceSectionContainer{
    padding: 0 2rem;
}
.titleContainer{
    padding-bottom: 2rem;
}
.resourceButtonsWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}
@media (min-width: 768px) {
    .applianceSectionContainer{
        padding: 0 4rem;
    }
}
@media (min-width: 768px) {
    .applianceSectionContainer{
        padding: 0 4rem;
    }
}
@media (min-width: 1920px) {
    .applianceSectionContainer{
        padding: 0rem;
    }
}