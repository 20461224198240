.toolTipContainer {
    position: relative;
    
}
.toolTipWrapper svg {
    height: 1.6rem;
    width: 1.6rem;
    display: flex;
    /* stroke: var(--color-gray-06); */
}

.toolTipInnerDiv {
    top: 0;
    right: 5px;
    position: absolute;
    z-index: 1;
}
.toolTipText {
   padding: 3rem; 
   background-color: white;
   border: 1px solid var(--color-gray-05);
   display: flex;
   width: 30rem; 
   /* width: min(1000rem, 50rem); */
   border-radius: 4rem;
}