/* MOBILE HEADER */
.dHero,
.dBody {
    display: none;
}

.hero {
    height: auto;
    display: grid;
    gap: 4rem;
    padding: 4rem 0;
}

.description {
    display: grid;
    gap: 1rem;
}

.body {
    padding: 4rem 0;
}

.bodyText {
    display: grid;
    gap: 2rem;
}

.text {
    display: grid;
    gap: .5rem;
}

.imageWrapper {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;
}

@media (min-width: 1024px) {

    .hero,
    .body {
        display: none;
    }

    .dHero {
        display: grid;
        height: 50rem;
        align-content: center;
        gap: 2rem;
    }

    .dBody {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        margin-bottom: 8rem;
    }

    .dTextContent {
        grid-row: 1;
        grid-column: 1/6;
    }

    .dTextWrapper {
        display: grid;
        gap: 2rem;
        margin: 2rem 0;
    }

    .dBenefitsWrapper {
        margin: 4rem 0;
    }

    .dBenefitsItem {
        display: grid;
        grid-template-columns: 1fr 5fr;
        grid-template-rows: repeat(3, auto);
        column-gap: 1rem;
        align-items: center;
    }

    .checkmark {
        grid-column: 1;
        grid-row: 1/-1;
    }

    .benefits {
        grid-column: 2;
        display: grid;
        gap: 1rem;
    }

    .dImage {
        grid-row: 1;
        grid-column: 7/-1;
        gap: 4rem;
    }

    .dText {
        display: grid;
        gap: 1rem;
    }

    .dImage {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
    }


}