

.row1Wrapper{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 2rem;
  padding-top: 4rem;
}
.rowWrapper{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 2rem;
  padding-top: 2rem;
}
.firstChartGroupRow2, .lastChartGroupRow2  {

  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* background-color: rgb(44, 12, 53); */
}
.lastChartGroupRow2{
  /* display: flex;
  flex-direction: column; */
  gap: 2rem;
  /* padding-top: 2rem; */
  /* background-color: aqua; */
}
.headerTextWrapper{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-top: 2rem;
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 

@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/