.imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 22vh; */
    height: 22rem;
    overflow: hidden;
}

.image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* or cover */
}
