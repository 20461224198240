.benefitCard {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: flex-start;

    padding-bottom: 2rem;
    border-bottom: 1px solid var(--color-gray-03);
    margin-bottom: 2rem;
}
.benefitCardText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* gap: .5rem; */
}
.benefitTitle > a{
    color: var(--color-gray-01);
   
}
.benefitTitle p{
    color: var(--color-gray-01);
  padding-top: .2rem;
    border-bottom: 2px solid transparent;
}
.benefitTitle  p:hover{
    color: black;
    border-bottom: 2px solid var(--color-red-03);
}
/* .benefitTitle > span{
    color: var(--color-gray-04);
    font-family: var(--text-semibold);
} */

.longBenefitText {
    display: none;
    color: var(--color-gray-01);
}
.shortBenefitText {
    display: flex;
    color: var(--color-gray-01);
    padding-top: .5rem;
}


.iconWrapper {
    height: 4rem;
    width: 4rem;
    display: flex;
    /* margin-bottom: auto; */
}

.iconWrapper svg {
    height: 100%;
    width: auto;
    object-fit: contain;
}


@media (min-width: 430px) {
  
}

@media (min-width: 768px) {
   

    .contentWrapper {
        padding: 0rem 4rem 2rem 4rem;
    }
    .benefitCardText {
        
        gap: 1rem;
    }


   

   

}




@media(min-width:1024px) {

    .longBenefitText {
        display: flex;
    }

    .shortBenefitText {
        display: none;
    }


}

@media(min-width:1440px) {
 .benefitCard {
        padding-bottom: 3rem;
        /* border-bottom: none; */
        margin-bottom: 3rem;
    }

}

@media (min-width: 1920px) {
   

    .contentWrapper {
        padding: 0;
    }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/