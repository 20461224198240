/* SKELETON */
.dHeader,
.dColumns,
.dMedia,
.largeImage {
    display: none;
}

/* MOBILE HEADER */

.headerText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.productImage {
    margin: 4rem 0;
}

.colorLegend {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.button {
    margin-top: 2rem;
}

.column {
    display: flex;
    flex-direction: column;

    gap: 2rem;
    margin: 4rem 0;
}

.items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


@media (min-width: 1224px) {

    .skMobileHeader,
    .skMobileColumns {
        display: none;
    }

    .dHeader {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 4rem 0;

    }

    .dTextContent {

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        margin-top: 4rem;
    }

    .dLegend {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-top: auto;
    }

    .dColumns {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }

    .dMedia {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(3, auto);
        gap: 1rem;
        margin-bottom: 8rem;
    }

    .mediaTitle {
        grid-row: 1;
        grid-column: 1/7;
    }

    .mediaButtonsWrapper {
        grid-row: 2;
        grid-column: 1/6;

    }

    .mediaButtons {
        display: grid;
        grid-template-columns: 5rem 5rem;
        gap: 1rem;
    }

    .dMediaItems {
        grid-row: 3;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
    }



    @media (min-width: 1424px) {
        .medImage {
            display: none;
        }

        .largeImage {
            display: block;
        }
    }

    /* 
    .skDeskHeader {
       
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: 1fr;
    }
    .skImageWrapper{
        grid-column: 2;
        justify-self: flex-end;
    }
    .skButton{
        margin-top: 1rem;
    }
    .skColorLegend{
        grid-row: 4/-1;
        grid-column: 1/-1;
        margin-top: 10rem;
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }

    .skHeader {
        background: plum;
        display: flex;
        flex-direction: column;
    }
    
    .skTextWrapper {
        grid-column:1 ;
        display: grid;
        grid-template-columns: repeat(12,1fr);
        grid-template-rows: 1fr repeat(4,auto);
        align-items: center;
    }
    
    
    .skTitle{
        grid-column: 1/-1;
        grid-row: 1/4;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .skButton{
        display: none;
    }
    .skButtonMobile{
       
    }
    .skColorLegendMobile{
        display: flex;
            flex-direction: row;
            gap: 2rem;
    }
    .skColorLegend{
        display: none;
    }
    .skImageWrapper{
        grid-column: 2;
    
    }
    .skCardsWrapper {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }
    .skComponentWrapper{
        
        background-color: var(--color-gray-05);
        display: flex;
        flex-direction: column;
        gap: 4rem;
        padding-top: 3rem;
    
    } */

}