.svgChartContainer{
    width: 100%;
    height: 100%;
    /* width: 38rem; */
    /* height: 20rem; */
    display: flex;
}

.svgChartContainer svg{
  
    height: 100%;
    width: 100%;
    object-fit: contain;
}