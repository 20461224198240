.scrollToTopcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 4rem;
    height: 4rem;
   
    background-color: white;
    
    border-radius: 100rem;
    border: 1px solid var(--color-gray-04);
    position: fixed;
    bottom: 7.5%;
    /* right: 10%; */
    right: 2%;
    z-index: 2;

    cursor: pointer;
}
.scrollToTopcontainer svg{
    height: 2rem;
    width: 2rem;
}
@media (min-width: 1024px) {
    .scrollToTopcontainer {
        width: 6rem;
        height: 6rem;
    }
}

