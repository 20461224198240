.scrollBarContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2rem;
}

.headerContainer{
    display: flex;
    flex-direction: row;
}

