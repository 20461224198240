
.searchResultsPreviewCardContainer {
    /* background-color: red; */
    display: grid;
    grid-template-columns: .23fr 1fr auto;
    grid-template-rows: auto;
    align-items: center;
    height: 10rem;
    gap: 1.7rem;
    background-color: white;
    border: 1px solid var(--color-gray-04);
    border-radius: 2.6rem;
    margin-bottom: 1rem;
    padding: 2rem;
}
.searchResultsPreviewCardContainer button {
   padding: 1rem;
}

.searchResultsPreviewCardContainer:first-child {
    margin-top: 1rem;
    /* border-bottom: 1px solid var(--color-gray-04); */
}

.searchResultsPreviewCardImageWrapper {}

.searchResultsPreviewCardImageWrapper img {
    width: 100%;
    height: 5rem;
    object-fit: contain;
}
.searchResultsPreviewCardText {
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: .4rem;
    color:var(--color-gray-03);
}

.clampedSubtitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}








/* @media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} */