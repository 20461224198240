/* SKELETON */
.skHeader {
    display: flex;
    flex-direction: column;
    /* height: 1000rem;
    background-color: red; */
}

.skTextWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    gap: 1rem;
}
.skTitle{
    gap: 1rem;
    display: flex;
    flex-direction: column;

}
.skCardsWrapper {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}
.skComponentWrapper{
    /* background-color: aqua; */
    background-color: var(--color-gray-05);
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-top: 3rem;

}