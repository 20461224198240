.pagination{
  /* background-color: #FAFAFA; */
  background-color: var(--color-gray-05);
 /* padding: 1.5rem 2rem; */
 padding: 0 2rem;
 font-size: 1.6rem;
 min-width: 1200px;
 max-width: 2000px;
 /* width: 100rem; */
}
.paginationContentWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonWrapper{
  display: flex;
  align-items: center;
  /* gap: 1.5rem; */
}
.pageNumberWrapper{
 display: flex;
 flex-direction: row;
 gap: .5rem;
}
.pagNum{
  color: var(--color-gray-01);
  font-family: inherit;
  /* background-color: aqua; */
  border: 1px solid var(--color-gray-02);
  border-radius: 2px;
  padding: 1rem;
}
.spreadDotsWrapper{
  padding-left: .5rem;
}
.chevronWrapper svg{
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  
}
.paginationButton:hover{
  cursor: pointer;
}
.pagNum:hover{
  cursor: pointer;
  background-color: var(--color-gray-04);
  color: var(--color-gray-02);
}
.pagNum.activePage{
  background-color: var(--color-gray-02);
  color: var(--color-gray-07);
}
.tableInfoText{
  color: black;
  font-family: var(--lg-ei-text-regular);
font-size: 1.6rem;
}
.paginationButton{
  background-color: inherit;
  border: none;
  color: var(--color-gray-01);
  font-family: var(--lg-ei-text-regular);
  font-size: 1.6rem;
}
.paginationButton:disabled{
  color: rgb(196, 196, 196);
  cursor: not-allowed;
}
.paginationButton:disabled{
  color: rgb(196, 196, 196);
  cursor: not-allowed;
}
.paginationButton:hover:not(:disabled){
  color: var(--color-red-03);
}


/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}

@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/