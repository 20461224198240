:root{

  --color-red-01: #A50034;
  --color-red-02: #EA1917;
  --color-red-03: #ED0602;
  --color-gray-01: #262626;
  --color-gray-02: #4A4946;
  --color-gray-03: #716F6A;
  --color-gray-04: #D0CBC1;
  --color-gray-05: #E6E1D6;
  --color-gray-06: #F0ECE4;
  --color-gray-07: #F6F3EB;

  --headline-light: "LG EI Headline Light","Microsoft Sans Serif", sans-serif;
  --headline-regular: "LG EI Headline Regular","Microsoft Sans Serif", sans-serif;
  --headline-semibold: "LG EI Headline SemiBold","Microsoft Sans Serif", sans-serif;
  --text-regular: "LG EI Text Regular","Microsoft Sans Serif", sans-serif;
  --text-bold: "LG EI Text Bold","Microsoft Sans Serif", sans-serif;
  --text-semibold: "LG EI Text SemiBold","Microsoft Sans Serif", sans-serif;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--color-gray-07);
  font-family: var(--text-regular);
  box-sizing: border-box;
  overflow-y: auto;
  /* line-height: 1.6; */
}
body::-webkit-scrollbar {
  width: 1rem;
}
body::-webkit-scrollbar-track {
  background: var(--color-gray-06);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-04);
  border-radius: 10px;
  border: 1px solid var(--color-gray-05);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-red-02);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
section,
form {
  margin: 0;
  padding: 0;
  font-weight: inherit;
}

@font-face {
  font-family: "LG EI Headline Light";
  font-style: normal;
  font-weight: normal;
  src: url("/public/fonts/LG_EI_Headline_Light.ttf") format("truetype");
  src: url("/public/fonts/LG_EI_Headline_Light.woff") format("woff");
}

@font-face {
  font-family: "LG EI Headline Regular";
  font-style: normal;
  font-weight: normal;
  src: url("/public/fonts/LG_EI_Headline_Regular.ttf") format('truetype');
  src: url("/public/fonts/LG_EI_Headline_Regular.woff") format('woff');
}

@font-face {
  font-family: "LG EI Headline SemiBold";
  font-style: normal;
  font-weight: normal;
  src: url("/public/fonts/LG_EI_Headline_Semibold.ttf") format('truetype');
  src: url("/public/fonts/LG_EI_Headline_Semibold.woff") format('woff');
}

@font-face {
  font-family: "LG EI Text Bold";
  font-style: normal;
  font-weight: normal;
  src: url("/public/fonts/LG_EI_Text_Bold.ttf") format('truetype');
  src: url("/public/fonts/LG_EI_Text_Bold.woff") format('woff');
}

@font-face {
  font-family: "LG EI Text Regular";
  font-style: normal;
  font-weight: normal;
  src: url("/public/fonts/LG_EI_Text_Regular.ttf") format('truetype');
  src: url("/public/fonts/LG_EI_Text_Regular.woff") format('woff');
}

@font-face {
  font-family: "LG EI Text SemiBold";
  font-style: normal;
  font-weight: normal;
  src: url("/public/fonts/LG_EI_Text_SemiBold.ttf") format('truetype');
  src: url("/public/fonts/LG_EI_Text_SemiBold.ttf") format('woff');
}


@keyframes gradientAnimation {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/