/* --------------------------------------------------------------------------------------- */
/* MAIN NAV */
/* --------------------------------------------------------------------------------------- */
.mainNavComponentContainer {
    background-color: var(--color-gray-06);
    border-bottom: 1px solid transparent;
    /* padding: 1.5rem 2rem; */

    /* border-bottom: 1px solid var(--color-gray-04); */

}

.mobileNavWrapper {
    padding: 2rem;
    /* display: flex; */
}
.dropdownLinks li{
    list-style: none;
}
/* --------------------------------------------------------------------------------------- */
/* MOBILE NAV */
/* --------------------------------------------------------------------------------------- */

.mobileMediumSearchConditionWrapper{
    width: 40rem;
}
.mobileNavComponentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.mobileLogoWrapper {
    height: 3.2rem;
    width: auto;
}

.mobileLogoWrapper img {
    object-fit: contain;
    height: 100%;
    width: auto;
}

.mobileIconsWrapper {
    display: flex;
    flex-direction: row;
    /* gap: 2.5rem; */
    gap: 3.5rem;
    align-items: center;
}

.mobileIconsWrapper svg {
    height: 2.4rem;
    width: 2.4rem;
    display: flex;
}

.mobileMenuBackIconWrapper {
    /* color: pink; */
    /* padding: 0 0 2rem 0; */

}

.mobileMenuBackIconWrapper svg {
    height: 2rem;
    width: 2rem;
}

.mobileNavDropdownMenuWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

#firstHeader {
    border-top: 1px solid var(--color-gray-04);
    padding-top: 2rem;
}

.mobileMenuHeader {
    /* background-color: aqua; */
    border-bottom: 1px solid var(--color-gray-04);
}

.headerText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 2rem;
}

.mobileMenuHeader svg {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
}

.mobileNavOptionsList a {
    color: black;
    font-size: 1.8rem;
}

.mobileNavOptionsList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
}

.mobileNavOptionsList li {
    list-style: none;
}



@media (min-width: 430px) {}
@media (min-width: 768px) {

    .mobileNavWrapper {
        padding: 2rem 4rem;
    }
    .mobileNavDropdownMenuWrapper {
        padding: 0 4rem;
    }
.hoverableText:hover {
   cursor: pointer;
}
}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}


/* --------------------------------------------------------------------------------------- */
/* DESKTOP NAV */
/* --------------------------------------------------------------------------------------- */


.desktopNavWrapper {
    display: none;
}



@media (min-width: 1280px) {
    .mainNavComponentContainer:hover {
        border-bottom: 1px solid var(--color-gray-04);
    }

    /* --------------------------------------------------------------------------------------- */
    /* MOBILE NAV */
    /* --------------------------------------------------------------------------------------- */

    .mobileNavWrapper {
        display: none;
    }

    /* --------------------------------------------------------------------------------------- */
    /* DESKTOP NAV */
    /* --------------------------------------------------------------------------------------- */

    .desktopNavWrapper {
        display: block;
        /* padding: 1rem 2rem; */
        /* padding: 0 2rem; */
    }

    .desktopNavContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.55rem 4rem;
        /* padding: 0.55rem 4rem; */
        /* padding: 0.55rem 0; */
        /* justify-content: space-between; */
    }

    .desktopNavLogoWrapper {
        /* height: 4.4rem; */
        height: 4rem;
        width: auto;
        padding-right: 2rem;
    }

    .desktopNavLogoWrapper img {
        object-fit: contain;
        height: 100%;
        width: auto;
    }

    .desktopNavMenuWrapper {
        display: flex;
        flex-direction: row;
        /* gap: 1rem; */
        /* gap: 2.5rem; */
        z-index: 10;
        
    }

    .desktopNavMenuHeader {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 1.5rem 2rem;
        color: var(--color-gray-02);
    }
    .desktopNavMenuHeader p {
        border-bottom: 2px solid transparent;
    }
    .desktopNavMenuHeader p:hover {
        color: var(--color-gray-01);
        border-bottom: 2px solid var(--color-red-03);
    }

    .desktopNavSearchWrapper {
 
        margin-left: auto;
        margin-right: 2.5rem;
        width: 40rem;
    }

    .desktopNavIconsWrapper {
        display: flex;
        flex-direction: row;
        gap: 2.5rem;

    }

    .desktopNavIconsWrapper svg {
        height: 2.4rem;
        width: 2.4rem;
        display: flex;

    }

    .dropdownSection {
        position: relative;
        /* z-index: 10; */
        z-index: 1;
    }

    .dropdownContainer {

        position: absolute;
        top: 100%;
        left: 0;
        width: auto;
        /* Let the container width be determined by the content */
        z-index: 999;
        /* Ensure the dropdown is above other elements */
    }
    .dropdownWrapper {
        position: relative;
        min-width: 30rem;
        /* width: 300px;  */
        /* Set a desired width or percentage */
        max-width: 100%;
        z-index: 999;
        /* Ensure it doesn’t overflow the container */
    }

    #applianceDropdownWrapperWidth {
        min-width: 70rem;
    }
    #resourcesDropdownWrapperWidth{
        min-width: 70rem;
    }
    .acountDropdownContainer {

        position: absolute;
        top: 178%;
        right: -50%;
        width: auto;
        z-index: 999;
    }
    #accountDropdownWrapperWidth{
        min-width: 20rem;
      
    }
    .dropdownContent {
        position: relative;
        /* Change to relative if width adjustments didn't work */
        background-color: var(--color-gray-06);
        padding: 3rem 4rem;
        /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
        /* Add shadow for a subtle 3D effect */
        /* border-radius: 4px; */
        /* Add rounding for a modern look */
        width: 100%;
        /* Ensure it takes the full width of dropdownWrapper */
        margin-top: 1rem;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        border-left: 1px solid var(--color-gray-04);
        border-right: 1px solid var(--color-gray-04);
        border-bottom: 1px solid var(--color-gray-04);
    }

    .dropdownLinks {
        display: flex;
        flex-direction: row;
        gap: 8rem;
    }

    .dropdownLinksList {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .dropdownLinksList a {
        color: var(--color-gray-01);
        font-size: 1.6rem;
    }

}


@media (min-width: 1440px) {
    .desktopNavWrapper {
        /* padding: 1rem 4rem; */
        /* padding: 0rem 4rem; */
    }
}

@media (min-width: 1920px) {
    .desktopNavContainer {
        padding: 0.55rem 0rem;
        padding: 1rem 0rem;
    }
    .desktopNavWrapper {
        padding: 0;
    }

}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/