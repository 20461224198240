.mainContainer{

  border-top: 1px solid var(--color-gray-04);
  z-index: 0;
}
.innerContainer{
  padding: 0 2rem;
}
.scrollHeaderWrapper {
 
}

.scrollHeader {
  /* grid-column: 2/12; */
}
.mainScrollingDivContainer {}

.container {
  /* background-color: var(--color-gray-05); */
  display: flex;
  overflow-x: auto;
  gap: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  /* padding-bottom: 6rem; */
}
.scrollWrapper {
  display: flex;
  align-items: center;
  position: relative;

}


/* For WebKit-based browsers */
.container::-webkit-scrollbar {
  width: 12px;
}

.container::-webkit-scrollbar-track {
  background: var(--color-gray-05);
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-04);
  border-radius: 10px;
  border: 3px solid var(--color-gray-05);
}

.container::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-red-02);
}
@media (min-width: 430px) {
  .scrollWrapper {
    /* padding: 2rem 8.5vw 4rem 8.5vw; */
  }
 
}

@media (min-width: 768px) {
  .container {
    padding-bottom: 3rem;
    /* padding-bottom: 6rem; */
  }
}

@media(min-width: 1440px){
  .innerContainer{
    padding: 0 4rem;
  }
}
@media (min-width:1920px) {

  .innerContainer{
    padding: 0;
  }
  .scrollWrapper {
    padding: 0;

  }
  .scrollHeaderWrapper {
 
  /* padding: 2rem 8rem 0 8rem; */
}
 /* .mainScrollingDivContainer:not(:last-child){
    border-bottom: 2px solid var(--color-red-03);
  } */
  


  

  

  
  
}