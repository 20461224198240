/* --------------------------------------------------------------------------------------- */
/* SEARCH INPUT */
/* --------------------------------------------------------------------------------------- */

.searchInputWrapper {
    background-color: white;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    width: 100%;
    padding: 1.2rem 2rem;
    border-top: 1px solid var(--color-gray-04);
    border-bottom: 1px solid var(--color-gray-04);
}
.searchInputWrapper.active{
    background-color: var(--color-gray-07);
    /* border: 10px solid var(--color-red-03); */
}
.input {
    width: 100%;
    border: none;
    font-size: 1.6rem;
    background-color: white;
    color: var(--color-gray-02);
}

.input::placeholder{
    color: var(--color-gray-03);
}
.input.active{
    background-color: var(--color-gray-07);
    color: var(--color-gray-01);  
}

.input.withResults{
    background-color: var(--color-gray-06);
}
.input:focus {
    outline: none;
}
.searchInputWrapper:hover{
    /* cursor: pointer; */
    /* border: 1px solid var(--color-gray-04); */
}
.searchInputWrapper.withResults {
  border: 1px solid var(--color-gray-04);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--color-gray-06);
    border-bottom: none;
}
.searchInputWrapper.focused,
.searchInputWrapper.focused input[type="search"] {
    background-color: var(--color-gray-07);
}
.desktopSearchIconWrapper svg {
    display: flex;
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
}

.searchPreviewWithResults {
    position: relative;
    height: 100rem;
    overflow-y: scroll;
}
.searchIconWrapper{
    /* background-color: aqua; */
/* padding: .5rem 1rem; */
padding: .5rem 1rem .5rem 0;
}
.searchIconWrapper svg {
    height: 1.6rem;
    width: 1.6rem;
    object-fit: contain;

}



.searchInputHeaderText{
    padding: 0 2rem 1rem 2rem;
    color: black;
    display: flex;
    gap: .5rem;
    flex-direction: column;
    align-items: center;
}

.searchInputFooterText{
    padding: 2rem;
    color: var(--color-gray-02);
    border-bottom: 1px solid var(--color-gray-04);
}

.noResultsCount{
    color: var(--color-gray-03);
    font-size: 1.8rem;
}
.activeResultsCount{
    color: var(--color-red-03);
    font-size: 1.8rem;
    font-family: var(--text-semibold);
}
.activeResultsCountText{
    color: var(--color-gray-03);
    font-size: 1.8rem;
    /* font-family: var(--text-semibold); */
}

@media (min-width: 1024px) {
    .searchInputWrapper {
        border: 1px solid var(--color-gray-05);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 26px;
        position: relative;
        background-color: white;
        padding: 1.3rem 1.8rem; 
        /* padding: 1.6rem 1.8rem;  */
      
    }
}
/* --------------------------------------------------------------------------------------- */
/* SEARCH PREVIEW */
/* --------------------------------------------------------------------------------------- */

/* .desktopSearchPreviewWithResults{

position: relative;
}
.desktopSearchPreviewWithResultsInnerDiv{
    position: absolute;
    height: 100rem;
    overflow-y: scroll;
    background-color: var(--color-gray-05);
} */



/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/