
.contentWrapper{
    padding: 0 2rem;
}
.categoryCardsContainer{
    /* background-color: aqua; */
    margin-bottom: 4rem;
}
.categoryCardsContainer:not(:last-child){
    border-bottom: 1px solid var(--color-gray-04);
    padding-bottom: 4rem;
}
.categoryCardsWrapper, .lowVolumeCategoryCardsWrapper {
   
    display: grid;
 
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 2rem;
    align-items:center;
    justify-content: center;
    padding-top: 1.5rem;
} 


/* .categoryCardsWrapper, .lowVolumeCategoryCardsWrapper {
   
    display: grid;
 
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 2rem;
    align-items:center;
    justify-content: center;
    padding-top: 1.5rem;
} */


.categoryTitleWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.categoryTitle a{
color: var(--color-gray-01);
/* color: var(--color-red-02); */
font-family: var(--text-semibold);
}
.categoryHeadline{
    color: var(--color-gray-02);
}
.categoryDescriptionWrapper{
    padding: 1rem 0;
    color: var(--color-gray-03);
}

.categoryDescriptionShort{
display: flex;
}
.categoryDescriptionLong{
display: none;
}

@media (min-width: 430px) {
    
    /* .categoryCardsWrapper {
        grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    }
    .lowVolumeCategoryCardsWrapper{
   
        grid-template-columns: repeat(auto-fit, minmax(10rem, 20rem));
    } */
}
@media (min-width: 640px) {
    /* .categoryCardsWrapper {
        grid-template-columns: repeat(auto-fit, minmax(19rem, 21rem));
        padding: 0;  
    } */
    .singleCategoryCardsWrapper{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(14rem, 27rem));
    }
    .categoryDescriptionWrapper{
        padding: 1rem 0 2rem 0;
    }
    
}

@media (min-width: 768px) {
  
    /* .categoryCardsWrapper {
        grid-template-columns: repeat(auto-fit, minmax(14rem, 23rem));
        align-items:normal;
        justify-content: normal;
    } */
}
@media (min-width: 834px) {
    /* .categoryCardsWrapper {
        grid-template-columns: repeat(auto-fit, minmax(23rem, 25rem));
    } */
}
@media (min-width: 1024px) {

    .categoryDescriptionShort{
        display: none;
        }
        .categoryDescriptionLong{
        display: flex;
        }
        .categoryDescriptionWrapper{
            padding: 1rem 0 3rem 0;
        }
        .categoryCardsWrapper {
            grid-template-columns: repeat(auto-fit, minmax(14rem, 27rem));
            align-items: normal;
            justify-content: normal;
        }
      
       
}
@media(min-width:1280px){
    .contentWrapper{
        padding: 0 4rem;
    }
    .categorySectionHeaderWrapper{
        width: 50%;
    }
}

@media(min-width:1920px){
    .contentWrapper{
        padding: 0;
    }
}
/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/