.backdropContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    /* For Safari */
    backdrop-filter: blur(10px);
    background: rgba(44, 13, 13, 0.307);
    z-index: 1000;
}