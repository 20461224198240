@media print {
    #main {
        display: none;
    }
}

.mainLayoutContainer{
    /* background-color: aqua;
    padding: 1rem; */
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.mainLayoutFooterWrapper{
    margin-top: auto;
}