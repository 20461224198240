/* .desktopVideoComponent{
    display: none;
}
.mobileVideoComponent{
    display: block;
 
} */
.applianceSectionContainer{
    padding: 0 2rem;
}
.container {
    display: flex;
    overflow-x: auto;
    gap: 2rem;
    padding-bottom: 2rem;
}
.sectionTitle{
    padding-bottom: 2rem;
}

/* For WebKit-based browsers */
.container::-webkit-scrollbar {
    width: 12px;
}

.container::-webkit-scrollbar-track {
    background: var(--color-gray-05);
}

.container::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-04);
    border-radius: 10px;
    border: 3px solid var(--color-gray-05);
}

.container::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-red-02);
}

.scrollWrapper {
    display: flex;
    position: relative;
}

@media (min-width: 430px) {}
@media (min-width: 768px) {
    .applianceSectionContainer{
        padding: 0 4rem;
    }
}
@media (min-width: 1024px) {
    .videosScrollHeader {
        padding-top: 2rem;
    }

    .scrollWrapper {
        padding-bottom: 2rem;
    }
}

@media (min-width: 1920px) {
    .applianceSectionContainer{
        padding: 0rem;
    }
}