/* --------------------------------------------------------------------------------------- */
/* SEARCH INPUT */
/* --------------------------------------------------------------------------------------- */

.searchInputWrapper {
    background-color: white;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    width: 100%;
    padding: 1.2rem 2rem;
    border: 1px solid var(--color-gray-03);
    /* border-top: 1px solid var(--color-gray-04);
    border-bottom: 1px solid var(--color-gray-04); */
    border-radius: 26px;
}
.searchInputWrapper.active{
    background-color: var(--color-gray-07);
    /* border: 10px solid var(--color-red-03); */
}
.input.active{
    background-color: var(--color-gray-07);
    color: var(--color-gray-01);
}
.input.withResults{
    background-color: var(--color-gray-06);
}
.searchInputWrapper:hover{
    cursor: pointer;
    /* border: 1px solid var(--color-gray-04); */
}
.searchInputWrapper.withResults {
  border: 1px solid var(--color-gray-04);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--color-gray-06);
    border-bottom: none;
}
.searchInputWrapper.focused,
.searchInputWrapper.focused input[type="search"] {
    background-color: var(--color-gray-07);
}
.desktopSearchIconWrapper svg {
    display: flex;
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
   
}
.searchPreviewWithResults {
    position: relative;
    height: 100rem;
    overflow-y: scroll;
}

.searchIconWrapper svg {
    height: 1.6rem;
    width: 1.6rem;
    object-fit: contain;
}

.input {
    width: 100%;
    border: none;
    font-size: 1.6rem;
    background-color: white;
    color: var(--color-gray-01);
}

.input:focus {
    outline: none;
}

@media (min-width: 1024px) {
    /* .searchInputWrapper {
        border: 1px solid var(--color-gray-01);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 26px;
        position: relative;
        background-color: white;
        padding: 1.2rem 1.8rem; 
      
    } */
}
/* --------------------------------------------------------------------------------------- */
/* SEARCH PREVIEW */
/* --------------------------------------------------------------------------------------- */

/* .desktopSearchPreviewWithResults{

position: relative;
}
.desktopSearchPreviewWithResultsInnerDiv{
    position: absolute;
    height: 100rem;
    overflow-y: scroll;
    background-color: var(--color-gray-05);
} */



/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/