.threeBoxWrapper{
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: auto;
    gap: 1rem;
    margin-bottom: 4rem;
}
.stackedLabelInputWrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    
}
.buttonWrapper{
    margin-bottom: 4rem;
}
.gridContainer{
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(2, auto);
    gap: 1rem;
}
.itemsWrapper{
    grid-column: 1/-1;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 2rem;
}
.bodyWrapper{
    grid-column: 1/-1;
    grid-row: 2;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 2rem;
}
.bodyItem1{
    grid-column: 1/3;
}
.bodyItem2{
    grid-column: 3;
}
.bodyItem3{
    grid-column: 4;
}