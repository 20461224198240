.table{
    width: 100%;
    background-color: white;
    /* background-color: var(--color-gray-07); */
    border: none;
    border-collapse: collapse;
    min-width: 1200px;
    max-width: 2000px;

}
.tableHeader{
    color: black;
    /* height: 80px; */
    /* background-color: #FAFAFA; */
    background-color: var(--color-gray-05);
    font-family: var(--lg-ei-text-bold);
 
}
.tableData{
    font-family: var(--lg-ei-text-regular);

    /* height: 80px; */
}
.tableHeader, 
.tableData{
    /* border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd; */

    border-right: none;
    border-bottom: 1px solid var(--color-gray-04);

    /* border: 1px solid #FAFAFA; */
    text-align: center;
    font-size: 1.6rem;
    padding: 1.5rem 2rem;
    height: 4rem;
    text-align: center;
    
}
.tableRows{
    height: 4rem; 
}
/* .tableRows:nth-child(even){background-color: #ffdddd;} */

.tableRows:hover {background-color: #ffdddd;}

.tableHeader:nth-child(1), .tableData:nth-child(1) {
    width: 25%; /* Example width for the first column */
}
.tableHeader:nth-child(2), .tableData:nth-child(2) {
    width: 15%; /* Adjust widths as needed */
}
.tableHeader:nth-child(3), .tableData:nth-child(3) {
    width: 15%; /* Adjust widths as needed */
}
.tableHeader:nth-child(4), .tableData:nth-child(4) {
    width: 15%; /* Adjust widths as needed */
}
.tableHeader:nth-child(5), .tableData:nth-child(5) {
    width: 25%; /* Adjust widths as needed */
}
.tableHeader:nth-child(6), .tableData:nth-child(6) {
    width: 10%; /* Adjust widths as needed */
}
.tableHeader:nth-child(7), .tableData:nth-child(7) {
    width: auto; /* Adjust widths as needed */
}