.categoryCardContainer {
    background-color: var(--color-gray-07);
    /* background-color: white; */
    border: 1px solid var(--color-gray-04);
    /* padding: .5rem; */
    border-radius: 2rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
}

.imageWrapper {
    height: 15rem;
    width: auto;
    padding: 3rem 4.4rem 2rem 4.4rem;
}

.imageWrapper img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.titleWrapper {
    padding: 0 2rem 2rem 2rem; color: var(--color-gray-01);
}

@media (min-width: 1024px) {
    .categoryCardContainer {
       padding: 1rem;
    }
    
} 