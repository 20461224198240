.sidebarContainer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, auto);
    /* background-color: var(--color-gray-05); */
    /* background-color: var(--color-gray-05); */
    /* background-color: #F0F2F5; */
    /* background-color: #FAFAFA; */
    /* background-color: var(--color-gray-06);
    padding: 0 3rem 0 0; */
    height: 100%;
    /* padding-right: .1rem; */
}

.sidebarWrapper {
    grid-row: 1/-1;
    grid-column: 1/-1;
    /* background-color: var(--color-gray-05); */
    background-color: var(--color-gray-06);
    /* height: 100vh; */
    /* box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); */
    /* background-color: aquamarine; */

    /* display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-columns: repeat(12,auto);
    background-color: rgb(21, 99, 99);
    height: 100%; */

}

.sidebarContent {
    grid-column: 1/-1;
    grid-row: 3;
    display: flex;
    /* gap: 1rem; */
    flex-direction: column;
    /* margin-top: 6rem; */
    padding-right: .2rem;

    /* height: 100%; */
}

.logoutLinkWrapper {
    padding: 0;
}

.logoutHeaderIconTextGroup {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 2rem;
    border-top: 1px solid black;
}

.icon svg {
    height: 2.4rem;
    width: 2.4rem;
    object-fit: contain;
    display: flex;

}

/* .icon:hover{
    cursor: pointer;
} */
.dropdownList {

    /* background-color: white; */
    display: flex;
    flex-direction: column;
    /* gap: 1.5rem; */
}

.dropdownList li {
list-style: none;
    padding: 2.2rem 6rem;

}

.dropdownList li:hover {
    /* box-shadow: 2px 0px var(--color-red-01); 
   background-color: #ffdddd; */

}

.dropdownList a {

    text-decoration: none;
    color: var(--color-gray-02);


}


.dropdownList p {
    transform: scale(1);
    transition: transform 0.1s ease, border-color 0.1s ease;
    border-bottom: 2px solid transparent;
    display: inline-flex;
}

.dropdownList p:hover {
    transform: scale(1.01);
    border-bottom: 2px solid var(--color-red-03);
}