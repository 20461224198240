.techLogosContainer {

    width: 100%;
    padding: 0 2rem;
}

.logosList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 1rem;
    padding-top: 2rem;
}

.logosList li {
    list-style: none;
}

.logoImageWrapper {
    width: 10rem;
    height: 10rem;
    background-color: var(--color-gray-07);

    border-radius: 1rem;
    padding: 1rem;
}

.printLogoImageWrapper {
    width: 10rem;
    height: 10rem;
    background-color: var(--color-gray-07);

    border-radius: 1rem;
    padding: 2rem;
}

.logoImage {
    display: flex;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media (min-width: 1024px) {

    .logoImageWrapper,
    .printLogoImageWrapper {
        width: 12rem;
        height: 12rem;

    }
}

@media (min-width: 768px) {
    .techLogosContainer {
        padding: 0 4rem;
    }
}

@media print {
    .techLogoTitle {
        display: none;
    }

    .logosList {
        /* column-gap: 20rem;
        row-gap: 50rem; */
        /* justify-content: center;
        align-items: center; */
        padding: 0;
        margin: 0;
        column-gap: 3rem;

    }

    .logoImageWrapper {
        height: 100%;
        width: 100%;
        background-color: none;
        border-radius: 0;
        padding: 0;
        margin: 0;
    }

    .printLogoImageWrapper {
        height: 100%;
        width: 100%;
        margin: 1rem 0 0 0;
        padding: 1rem;

    }

    .logoImage {
        height: 5rem;
        width: 7rem;
        object-fit: contain;
    }
}

@media (min-width: 1920px) {
    .techLogosContainer {
        padding: 0rem;
    }
}