.containerGrid {
    display: grid;
    grid-template-rows: 1fr;
}

.wrapperGrid {
    grid-column: 2 / -2;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
}
.sWrapperGrid {
    grid-column: 1/-1;
    grid-row: 1;
    display: grid;
  
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
}
.gridItem{
    grid-column: 1/-1;
}

@media (min-width: 1620px) {
    .gridItem {
        grid-column: 2/12;
    }
} 
@media (min-width: 1920px) {
    .sWrapperGrid {
        padding: 0rem;
    }
} 

@media (min-width: 2220px) {
    .containerGrid{
        grid-template-columns: repeat(24, 1fr);
    }
    .wrapperGrid{
        grid-column:5/21;
        grid-template-columns: repeat(12, 1fr);

    }
    .gridItem {
        grid-column: 1/-1;
    }
} 


/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {}
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/