.datePickerContainer {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center;
 justify-content: flex-end;
 margin-top: 3.4rem; */
}
.quickFilterButtonWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}
.inputContainer {
    background-color: white;
    /* padding: .75rem 1.14rem; */
    padding: .75rem 1.14rem;
    /* border-radius: .8rem; */
    border: 1px solid var(--color-gray-05);
    display: flex;
    
   
}

@media (min-width: 580px) {
    .quickFilterButtonWrapper {
  
    flex-direction: row;
}
}
@media (min-width: 1280px) {
    .datePickerContainer {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
 justify-content: flex-start;
 /* margin-top: 3.4rem; */
}

.inputContainer {

    padding: .75rem 1.14rem;
    /* border-radius: .8rem; */
    border: 1px solid var(--color-gray-05);
    display: flex;
    width: 20%;
    height: max-content;
}
.quickFilterButtonWrapper{
    margin-bottom: 0;
}
}
@media (min-width: 1920px) {
    .datePickerContainer {
   
 justify-content: flex-end;
 margin-top: 3.4rem;

}

.inputContainer {

    padding: .75rem 1.14rem;
    /* border-radius: .8rem; */
    border: 1px solid var(--color-gray-05);
    display: flex;
    width: 20%;
    height: max-content;
}
.quickFilterButtonWrapper{
    margin-bottom: 0;
}
}


.quickFilterButtonWrapper button{
    background-color: white;
    border: 1px solid var(--color-gray-05);
    color: var(--color-gray-01);
    font-family: inherit;
    font-size: 1.4rem;
    height: 5.235rem;
    /* border-radius: .8rem; */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.filterButton button {
    background-color: var(--color-red-03);
    color: white;
    
}



.dateLabel{
    font-size: 1.4rem;
}
.dateInput {
    width: 100%;
    height: max-content;
    border: none;
    color: var(--color-gray-02);
    font-size: 1.4rem;
    font-family: inherit;
}

.dateInput:focus-visible {
    outline: none;
}


.quickFilterButtonWrapper .activeButton {
    background-color: var(--color-gray-01);
    color: white;
    font-weight: bold;
    border-radius: 0.5rem;
}

.quickFilterButtonWrapper .activeButton:hover {
    background-color: var(--color-gray-02);
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/