/* --------------------------------------------------------------------------------------- */
/* PAGE HERO */
/* --------------------------------------------------------------------------------------- */
.heroTitle {
    display: inline-flex;
    font-family: var(--headline-semibold);
    font-size: 2.8rem;
}

.categoryHeroTitle {
    display: inline-flex;
    font-family: var(--headline-semibold);
    font-size: 2.8rem;
}

.heroSubtitle {
    display: inline-flex;
    font-size: 1.6rem;
    font-family: var(--text-semibold);
}

.heroDescription {
    display: inline-flex;
    font-size: 1.6rem;
    /* font-size: 1.6rem; */
}


.heroTertiaryTitle {
    display: inline-flex;
    font-size: 1.4rem;
    font-family: var(--text-regular);
}

/* --------------------------------------------------------------------------------------- */
/* PRODUCT
/* --------------------------------------------------------------------------------------- */
.productPageTitle {
    font-size: 2rem;
    color: var(--color-gray-03);
}

.productPageSubtitle {
    font-family: var(--headline-semibold);
    /* font-weight: 600; */
    font-size: 2.8rem;
    color: var(--color-gray-01);
}

.productPageColorSwatch {

    display: inline-flex;
    font-size: 1.8rem;
    color: var(--color-gray-02);
}

.productPageSpecTitle {
    /* color set in component*/
    font-size: 2rem;
}

.productPageSpecList {

    font-size: 1.8rem;
    color: var(--color-gray-01);
}


.productCardTitle {
    font-size: 1.6rem;
    color: var(--color-gray-03);
}

.productCardSubtitle {
    font-size: 2rem;
}


.productSearchTitle {
    font-size: 1.6rem;
}

.productSearchSubtitle {
    font-size: 1.6rem;
    /* font-family: var(--text-semibold); */
}

.productSearchNavTitle {
    font-size: 1.4rem;
}

.productSearchNavSubtitle {
    font-size: 1.4rem;
    /* font-family: var(--text-semibold); */
}


.productBuilderTitle {
    font-size: 1.8rem;
}

.productBuilderSubtitle {
    font-size: 2.3rem;
}

/* .productBuilderCategory {
    font-size: 1.6rem;
} */
.productBuilderSpecTitle {
    font-size: 2rem;
}

.productBuilderSpecList {
    font-size: 1.6rem;
}


/* EXTRAS */

.productPageTertiary {
    font-size: 1.8rem;
    color: var(--color-gray-03);
}

.productPageSection {
    font-size: 2rem;
    font-family: var(--text-semibold);
    color: var(--color-gray-02);
}

.productCardTertiaryTitle {
    font-size: 1.4rem;
}

.productCardListText {
    font-size: 1.2rem;
}



/* --------------------------------------------------------------------------------------- */
/* COLLAR TEXT */
/* --------------------------------------------------------------------------------------- */



/* --------------------------------------------------------------------------------------- */
/* PAGE BODY */
/* --------------------------------------------------------------------------------------- */
.bodyTitle {
    display: inline-flex;
    /* font-family: var(--headline-regular);
     font-weight: 600; */
    font-family: var(--headline-semibold);
    font-size: 2.8rem;

    /* letter-spacing: 1px; */
}

.bodySubtitle {
    display: inline-flex;
    font-size: 1.6rem;
}
.bodySubtitleBold {
    display: inline-flex;
    font-size: 1.6rem;
    font-family: var(--text-semibold);
}
.bodyDescription {
    display: inline-flex;
    font-size: 1.4rem;

}

.bodyCallout {
    display: inline-flex;
    font-size: 1.6rem;
}

.bodyCalloutTitle {
    font-family: var(--text-regular);
    display: inline-flex;
    font-size: 2rem;
}

.bodyCalloutBoldTitle {
    font-family: var(--text-semibold);
    display: inline-flex;
    font-size: 2rem;
}

.bodyCountTitle {
    font-family: var(--text-semibold);
    display: inline-flex;
    font-size: 1.4rem;
}

/* OLD EXTRAS */

.pageSubtitle {
    font-size: 2.4rem;
}


.pageTertiaryTitle {
    font-size: 2.2rem;
}




/* --------------------------------------------------------------------------------------- */
/* NAVIGATION + FOOTER */
/* --------------------------------------------------------------------------------------- */
.navTitleText {
    font-size: 1.8rem;

}

.stickyNavTitleText {
    font-size: 1.6rem;
    /* font-family: var(--text-semibold); */
}

.mobileNavTitle {
    font-size: 2rem;
}

.footerTitle {
    font-size: 1.6rem;
    /* font-size: 1.6rem; */
    /* font-family: var(--text-semibold); */
}

.footerStatement {
    font-size: 1.6rem;
    /* font-size: 1.6rem; */
    /* font-family: var(--text-semibold); */
}

/* EXTRAS */
.footerMenuItem {
    font-size: 1.4rem;
}

/* --------------------------------------------------------------------------------------- */
/* FORMS */
/* --------------------------------------------------------------------------------------- */
/* NEW */
.formHeaderTitle {
    font-size: 2.6rem;
    font-family: var(--headline-semibold);
}

.formSectionTitle {
    font-size: 2.6rem;
    /* color: var(--color-gray-01);
    font-family: var(--headline-semibold); */
}

.formLabel {
    font-size: 1.8rem;
    color: var(--color-gray-02);
    font-family: var(--text-regular);
}

.formSecondaryLabel {
    font-size: 1.8rem;
    color: var(--color-gray-03);
    font-family: var(--text-regular);
}

.toolTip {
    font-size: 1.4rem;
    color: var(--color-gray-03);
    font-family: var(--text-regular);
}

/* --------------------------------------------------------------------------------------- */
/* MODALS + ALERTS */
/* --------------------------------------------------------------------------------------- */
.alertTitle {
    font-size: 1.6rem;
    font-family: var(--text-semibold);
}

.modalTitle {
    font-size: 3rem;
    font-family: var(--headline-regular);
}


.modalTertiaryTitle {
    font-size: 2rem;
}


/* --------------------------------------------------------------------------------------- */
/* PORTAL */
/* --------------------------------------------------------------------------------------- */
.smallPortalTitle {
    font-size: 1.8rem;
    /* color: var(--color-gray-01) */
}

/* --------------------------------------------------------------------------------------- */
/* PRINT MODE */
/* --------------------------------------------------------------------------------------- */
.coverTitle {
    font-size: 6rem;
    font-family: var(--headline-regular);
    font-weight: 600;
}

.coverSubtitle {
    font-size: 3.5rem;
}

/* --------------------------------------------------------------------------------------- */
/* SEARCH */
/* --------------------------------------------------------------------------------------- */
/* EXTRAS */
.searchTitle {
    font-size: 2.4rem;
    font-family: var(--text-semibold);
}

.searchTertiaryTitle {
    font-size: 1.6rem;
}

.searchSubtitle {
    font-size: 2rem;
}

.productSearchTitle {
    font-size: 1.6rem;
}

.productSearchSubtitle {
    font-size: 1.6rem;
}

.productSearchNavTitle {
    font-size: 1.4rem;
}

.productSearchNavSubtitle {
    font-size: 1.4rem;
}


@media (min-width: 430px) {

    /* --------------------------------------------------------------------------------------- */
    /* PAGE HERO */
    /* --------------------------------------------------------------------------------------- */

    .heroTitle {
        font-size: 3.6rem;
    }

    .categoryHeroTitle {
        font-size: 3.6rem;
    }

    .heroSubtitle, .bodySubtitle, .bodySubtitleBold {
        font-size: 2rem;
    }

    .heroDescription {
        font-size: 2rem;
    }

    .heroTertiaryTitle {
        font-size: 2rem;
    }


}

@media (min-width: 768px) {
    /* --------------------------------------------------------------------------------------- */
    /* PRODUCT
/* --------------------------------------------------------------------------------------- */


    .productPageSubtitle {
        font-size: 3.6rem;
    }

    .productPageTitle {
        font-size: 2.4rem;
    }

    .productPageSpecTitle {
        font-size: 2.2rem;
    }

    .productPageSpecList {
        font-size: 1.8rem;
    }

    .productPageTertiary {
        font-size: 2rem;
    }

    .productPageSection {
        font-size: 2.2rem;
    }

}

@media (min-width: 834px) {

    /* --------------------------------------------------------------------------------------- */
    /* PAGE HERO */
    /* --------------------------------------------------------------------------------------- */
    .heroTitle {
        font-size: 4.4rem;
    }

    .categoryHeroTitle {
        font-size: 4.4rem;
    }



    /* --------------------------------------------------------------------------------------- */
    /* PAGE BODY */
    /* --------------------------------------------------------------------------------------- */
    .bodyTitle {
        font-size: 3.6rem;
    }

    /* .bodySubtitle, .bodySubtitleBold {
        font-size: 1.8rem;
    } */
  
    .bodyDescription {
        font-size: 1.6rem;
    }

    .bodyCalloutTitle {
        font-size: 2.4rem;
    }

    .bodyCalloutBoldTitle {

        font-size: 2.4rem;
    }

    .bodyCallout {
        display: inline-flex;
        font-size: 1.8rem;
    }

    .bodyCountTitle {
        font-size: 1.6rem;
    }

    /* .productPageSpecTitle {
        font-size: 2rem;
    } */


    /* --------------------------------------------------------------------------------------- */
    /* FORM */
    /* --------------------------------------------------------------------------------------- */
    /* NEW */
    .formHeaderTitle {
        font-size: 3.6rem;
    }



}


@media (min-width: 1024px) {

    /* --------------------------------------------------------------------------------------- */
    /* PAGE HERO */
    /* --------------------------------------------------------------------------------------- */
    /* .heroTitle {
        font-size: 5.6rem;
    }

    .heroSubtitle {
        font-size: 2.2rem;
    }

    .heroTertiaryTitle {
        font-size: 2.2rem;
    }

    .heroDescription {
        font-size: 2.4rem;
    } */

    /* --------------------------------------------------------------------------------------- */
    /* PAGE BODY */
    /* --------------------------------------------------------------------------------------- */
    .bodyTitle {
        font-size: 4.4rem;
    }

    .bodyDescription {
        font-size: 1.8rem;
    }

    /* .bodyCallout {
        font-size: 1.8rem;
    }

    .bodyCalloutTitle {
        font-size: 2rem;
    } */

    .bodyCountTitle {
        font-size: 1.8rem;
    }

    /* --------------------------------------------------------------------------------------- */
    /* PRODUCT
/* --------------------------------------------------------------------------------------- */
    /* .productPageTitle {
        font-size: 2.4rem;
    }

    .productPageSubtitle {
        font-size: 3.2rem;
    } */

    .productPageSpecTitle {
        /* NEW */
        /* font-size: 2rem; */
        font-size: 2.4rem;
    }

    .productPageSpecList {
        /* NEW */
        /* font-size: 1.8rem; */
        font-size: 2rem;
        /* color: var(--color-gray-03); */
    }

    .productBuilderTitle {
        font-size: 2.4rem;
        /* font-size: 1.8rem; */
    }

    .productBuilderSubtitle {
        font-size: 3.2rem;
        /* font-size: 2.3rem; */
    }



    .productBuilderSpecTitle {
        font-size: 2rem;
    }

    .productBuilderSpecList {
        /* NEW */
        /* font-size: 2rem; */
        font-size: 1.6rem;
    }


    /* EXTRAS */
    .productPageTertiary {
        font-size: 2.2rem;
    }

    .productPageSection {

        font-size: 2.4rem;
    }

    /* --------------------------------------------------------------------------------------- */
    /* NAVIGATION + FOOTER */
    /* --------------------------------------------------------------------------------------- */

    .stickyNavTitleText {
        font-size: 1.8rem;
    }
    .footerTitle {
        font-size: 1.8rem;
        /* font-size: 1.6rem; */
        /* font-family: var(--text-semibold); */
    }
    
    .footerStatement {
        font-size: 1.6rem;
        /* font-size: 1.6rem; */
        /* font-family: var(--text-semibold); */
    }
    
    /* EXTRAS */
    .footerMenuItem {
        font-size: 1.6rem;
    }

       /* --------------------------------------------------------------------------------------- */
    /* FORM */
    /* --------------------------------------------------------------------------------------- */
    /* NEW */
    .formHeaderTitle {
        font-size: 4rem;
    }

}



@media (min-width: 1280px) {

    .productPageTitle {
        font-size: 2.8rem;
    }

    .productPageSubtitle {
        font-size: 4rem;
    }
}

@media (min-width: 1440px) {

    .heroTitle {
        font-size: 5.6rem;
    }

    .heroSubtitle .bodySubtitle, .bodySubtitleBold {
        font-size: 2.2rem;
    }

    .heroTertiaryTitle {
        font-size: 2.2rem;
    }

    .heroDescription {
        font-size: 2.4rem;
    }

    .productPageTitle {
        font-size: 3rem
    }

    .stickyNavTitleText {
        font-size: 2rem;

    }

    .productPageSubtitle {
        font-size: 4.4rem;
    }


}

@media (min-width: 1920px) {

    /* .bodySubtitle, .bodySubtitleBold {
        font-size: 2.2rem;
    } */

    .bodyCallout {
        font-size: 2.2rem;
    }
/* 
    .bodyCalloutTitle {
        font-size: 3rem;
    } */

    .bodyCalloutTitle {
        font-size: 2.6rem;
    }
    .bodyCalloutBoldTitle {
        font-size: 2.6rem;
    }

    /* --------------------------------------------------------------------------------------- */
    /* PAGE HERO */
    /* --------------------------------------------------------------------------------------- */
    /* .heroTitle {
        font-size: 4.4rem;
    } */
    /* 
    .heroSubtitle {
        font-size: 3.2rem;
    }

    .heroTertiaryTitle {
        font-size: 3rem;
    }

    .heroDescription {
        font-size: 2.4rem;
    } */


}