
.mobile {
    background-color: var(--color-gray-07);
    padding-top: 2rem;
    border-top: 1px solid var(--color-gray-04);
}

.desktop {
    display: none
}

.contentWrapper {
    padding: 0 2rem;
    /* background: red; */
}

.emptyListHeader {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.headerTitle {
    color: var(--color-gray-01);
}

.headerSubtitle {
    color: var(--color-gray-02);
    /* font-family: var(--text-semibold); */
}

.headerDescription, .description {
    color: var(--color-gray-02);

}


/* mobile */
.subtitle {
    color: var(--color-gray-07);
    /* font-family: var(--text-semibold); */
}

.descriptionWrapper {
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.description {
    color: var(--color-gray-02);
}

.introductionGridMobile {
    /* background-color: aqua; */
    display: grid;
    grid-template-columns: repeat(12, auto);
    grid-template-rows: repeat(6, auto);
}

.backgroundScreen {
    grid-row: 1/3;
    grid-column: 1/-1;
    background-color: var(--color-gray-05);
    border-top: 1px solid var(--color-gray-04);
    border-bottom: 1px solid var(--color-gray-04);
    padding: 2rem 2rem 3rem 2rem;
    /* padding: 2rem 0 3rem 0; */
    display: flex;
    flex-direction: column;
}

.instructionsWrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, auto);
}

.instructions {
    grid-column: 1/-1;
    grid-row: 1;

    display: flex;
    flex-direction: column;
    gap: 2rem;
    
}

.stepsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
   /* background-color: var(--color-gray-06);
   padding: 2rem; */
    margin-bottom: 2rem;
   
}

.stepCount {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.stepDescription {
    color: var(--color-gray-02);
    /* font-family: var(--text-semibold); */
}

.callout {
    grid-column: 1/-1;
    grid-row: 2;
    padding-top: 1rem;
    color: var(--color-gray-02);
}

.character {
    display: none;
}

.featureButtons {
    grid-row: 4;
    grid-column: 1/-1;
    padding: 2rem 0;
}

.desktopButton {
    display: none;
}

.character {
    display: flex;
}

.character {
    display: flex;
    grid-row: 1/-1;
    grid-column: 1/-1;
    justify-self: end;
    align-self: end;
    /* padding-left: 12rem; */
}

.charcterImageWrapper {
    height: 30rem;
    width: auto;
    /* padding-left: 15rem; */

}

.charcterImageWrapper img {
    height: 100%;
    /* width: 100%; */
    object-fit: contain;
    display: flex;
}

.instructions {
    grid-column: 1/10;
    grid-row: 1;
    padding-right: .6rem;
}

.callout {
    grid-column: 1/9;
}

.featureButtons {
    grid-column: 1/10;
}

@media (min-width: 430px) {

    .desktopButton {
        display: flex;
    }

    .mobileButton {
        display: none;
    }

    .instructions {
        grid-column: 1/10;
        grid-row: 1;
    }

    .callout {
        grid-column: 1/10;
    }
    .charcterImageWrapper {
        height: 32rem;
        width: auto;
        /* padding-left: 15rem; */
    
    }
}
@media (min-width: 540px) {

   
    .charcterImageWrapper {
        height: 40rem;
        width: auto;
        /* padding-left: 15rem; */
    
    }
}
@media(min-width:768px){

    .contentWrapper {
        padding: 0 4rem;
    }
    .backgroundScreen {
    
        padding: 2rem 4rem 3rem 4rem;
    
    }
    
    
}
@media (min-width: 1024px) {
    .mobile {
        display: none;
    }

    .desktop {
        display: block;
        /* background-color: var(--color-gray-05); */
        background-color: var(--color-gray-05);
    }

    .desktopEmptyListGrid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, auto);
        padding: 3rem 0 6rem 0;
    }

    .lgMan {
        grid-row: 1/-1;
        grid-column: 6/-1;
        /* grid-column: 7/-1; */
        justify-self: end;
        align-self: center;
    }

    .lgManImageWrapper {
        height: 50rem;
        width: auto;
    }

    .lgMan img {
        height: 100%;
        /* width: auto; */
        object-fit: contain;
    }
    .lgGirl{
        /* background-color: var(--color-gray-06);
        border-radius: 40rem; */
        height: auto;
        width: 45rem;
      
        /* width: 50rem; */
    }
    .lgGirl img{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .emptyListHeader {
        grid-column: 1/7;
        grid-row: 1;
    }

    .desktopCallout {
        /* background-color: aqua; */
        grid-column: 1/-1;
        padding-top: 3rem;
        padding-bottom: 2rem;
        color: var(--color-gray-02);
    }


    .instructionsWrapper {
        grid-column: 1/8;
        grid-row: 2;
    }

    .listCountStatement {
        display: flex;
        flex-direction: row;
        align-items: center;
    }


    .descriptionWrapper {
        /* padding-top: 1rem; */
        padding-bottom: 3rem;
    }
    .featureButtons{
        grid-column: 1/4;
    }
}

@media (min-width: 1280px) {
    /* .instructions {
        grid-column: 1/10;
        grid-row: 1;
    } */
    .lgGirl{
        /* background-color: var(--color-gray-06);
        border-radius: 40rem; */
        height: auto;
        width: 60rem;
    }
    /* .contentWrapper {
        padding: 2rem 4rem;

    } */

    .stepsWrapper{
        padding: 3rem;
        border-radius: 4rem;
        /* padding-bottom: 2rem; */
        /* margin-top: 2rem; */
        background-color: var(--color-gray-06);
        
       
    }
}

@media (min-width: 1440px) {
    .contentWrapper {
        padding: 2rem 4rem;
        /* padding: 3rem 4rem 0 4rem; */
    }
}

@media (min-width: 1920px) {
    .contentWrapper {
        padding: 0rem 0;

    }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/