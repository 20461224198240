
.contentWrapper{
    /* padding: 2rem 0; */
    padding: 2rem;
}
.fContentWrapper{
    /* padding: 2rem 0; */
    padding: 2rem;
}
.contentWrapperBase{
    padding: 2rem;
}
.wrapper2 {
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
}

.wrapper3 {
    background-color: var(--color-gray-05);
    /* border-top: 1px solid var(--color-gray-04); */

}

.section1 {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.section3 {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
color: var(--color-gray-03);
}

.columnList {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.columnList li{
    font-size: 1.4rem;
    list-style: none;
    
}
.columnList li a{
    color: var(--color-gray-03);
    transform: scale(1);
    transition: transform 0.1s ease, border-color 0.1s ease;
    border-bottom: 2px solid transparent;
   /* color: var(--color-gray-01); */
    display: inline-flex;
}
.columnList li:first-child {
    padding-top: .5rem;
}
.columnList li a:hover{
    transform: scale(1.0);
    color: var(--color-gray-01);
    border-bottom: 2px solid var(--color-red-03);
}

@media (min-width: 390px) {

    /* .wrapper1,
    .wrapper2,
    .wrapper3 {
        padding: 2rem;
    } */

    /* .section1{
        gap: 3rem;
    } */
    .columnList li:first-child {
        padding-top: 1rem;
    }
}

@media (min-width: 430px) {}

@media (min-width: 768px) {
    .contentWrapper{
        padding: 3rem 4rem;
    }
    .fContentWrapper{
        padding: 2rem 4rem;
    }
}

@media (min-width: 834px) {
     
    .section1 {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
    }

    .section2 {
        grid-column: 1/ -1;
    }

    .section3 {
        grid-column: 1 / -1;
    }

    .columnList {
        gap: 1rem;
    }
}
@media (min-width: 834px) {
     
    .section1 {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
    }

    .section2 {
        grid-column: 1/ -1;
    }

    .section3 {
        grid-column: 1 / -1;
    }

    .columnList {
        gap: 1rem;
    }
}
@media (min-width: 1024px) {
    .section1 {
        grid-template-columns: repeat(4, 1fr);
    }
    .columnList li{
        font-size: 1.6rem;
    }
    
}
@media (min-width: 1280px) {
  
    .section1 {
        grid-template-columns: repeat(6, 1fr);
    }

}


@media (min-width: 1440px) {
   
    .section1 {
        grid-column: 2 / 12;
    }

    .section2 {
        grid-column: 1 / 12;
    }

    .section3 {
        grid-column: 2 / 12;
    }
}

@media (min-width: 1920px) {
    .contentWrapper{
        padding: 3rem 0;
    }
    .fContentWrapper{
        padding: 2rem 0;
    }
}