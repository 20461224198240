.contentWrapper {
    padding: 2rem;
}

.introductionContainer {
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-05);
}

.introductionTextWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.introTitleText {
    color: var(--color-gray-01);
}

.introSubtitleText {
    color: var(--color-gray-01);
    /* font-family: var(--text-semibold); */
}

.introDescriptionText {
    color: var(--color-gray-01);
}

.longIntroDescriptionText {
    display: none;
}

.shortIntroDescriptionText {
    display: flex;
}

.imageWrapper {
    width: auto;
    display: flex;
    justify-content: center;
}

/* .headImage1 img,
.headImage2 img,
.rep1 img,
.rep2 img {
    height: 100%;
    width: auto;
    object-fit: contain;
    display: flex;
} */

.headImageGroup {
    display: flex;
    /* grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr; */
    align-items: center;
    justify-content: center;
    padding: 2rem 0 4rem 0;
}

.headImage1 {
    display: none;
}

/* .headImage2 {
    height: 20rem;
} */
.rep1 {
    height: 30rem;
    width: auto;
}

.rep1 img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.rep2 {
    display: none;
}

.cardWrapper {
    /* background-color: var(--color-gray-06);
    padding: 2rem;
    border-radius: 2rem; */
}

@media (min-width: 437px) {

    .headImage1 {
        height: 15rem;
        width: auto;
        display: flex;
    }
    .headImage1 img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

}

@media (min-width: 724px) {

    .contentWrapper {
        padding: 3rem 4rem;
    }


    .introductionContainer {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, auto);
        align-items: center;

        /* row-gap: 2rem; */
        /* padding: 2rem 0 4rem 0; */
        /* background-color: aqua; */
    }

    .headImage1 {
        height: 12rem;
    }

    .introductionTextWrapper {
        grid-column: 1/-1;
        grid-row: 1;
        display: grid;
        padding-bottom: 2rem;
    }

    .cardWrapper {
        grid-column: 1/8;
        grid-row: 2/-1;
        /* padding-right: 2rem; */
    }

    .imageWrapper {
        grid-column: 10/-1;
        grid-row: 2/-1;
        justify-self: end;
        padding-left: 24rem;
        /* height: 40rem; */

    }



    .headImageGroup {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .headImage1 {
        /* display: none; */
    }

    .rep2 {
        height: 28rem;
    }

    .introDescription {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        grid-row: 2;
        grid-column: 1/6;
    }

    .introDescriptionHeader {
        display: none;
    }

    .introTitle {
        padding-bottom: 2rem;
    }

    .rep2 {
        display: flex;
    }

    .rep1 {
        display: none;
    }


}

/* @media (min-width: 768px) {
    .headImage1 {
      height: 15rem;
    }
    .headImage2 {
        height: 30rem;
    } 
    .contentWrapper{
        padding: 2rem 4rem;
    }
} */
@media (min-width: 1020px) {
    .contentWrapper {
        padding: 4rem;
    }

    .imageWrapper {
        padding-left: 0;
/* grid-row: 1/-1;
        grid-column: 8/-1; */
        grid-column: 8/-1; 
        grid-row: 3/-1;
        justify-self: normal;
    }
    .introductionTextWrapper {
        grid-column: 1/-1;
        /* padding-bottom: 4rem; */
     
    }

    .cardWrapper {
        grid-column: 1/7;
        align-self: baseline;
        padding-top: 3rem;
    }

    .headImage1 {
        height: 17rem;
    }

    /* .headImage2 > .rep1{
        display: none;
    }  */
    .rep2 {
        display: flex;
        height: 35rem;
    }
    /* .introductionTextWrapper {
        
        padding-bottom: 5rem;
    } */
    /*   
    .headImage2 > .rep1{
        display: none;
    } 
    .rep2{
        display: flex;
        height: 34rem;
    } */
}

@media(min-width:1240px) {
    /* .introductionContainer {
        padding: 4rem 0;
    } */

    /* .headImage1 {
        height: 20rem;
    } */

    .imageWrapper {
        justify-self: flex-end;
        /* align-self: flex-start; */
        grid-row: 1/-1;
        margin-bottom: 4rem;
    }

    /* .rep2 {

        height: 40rem;
    } */

    /* .headImageGroup {
        flex-direction: row;
    } */

    /* .headImage1 {
        height: 25rem;
    }

    .headImage2 {
        height: 50rem;
    } */
    .introductionTextWrapper {
        grid-column: 1/8;
    }

    .cardWrapper {
        grid-column: 1/8;
    }
}

@media (min-width: 1920px) {

    .headImageGroup {
        display: flex;
        flex-direction: row;

        align-items: center;
        justify-content: center;
    }

    .headImage1 {
        height: 25rem;

    }

    .headImage2 {
        height: auto;
    }

    .rep1 {
        height: 50rem;
        display: flex;
    }

    .rep2 {
        display: none;
    }

    .cardWrapper {
        grid-column: 1/7;
    }

    .introductionContainer {

        padding: 6rem 0 3rem 0;

    }

    .introductionTextWrapper {
        /* background-color: violet; */
        grid-column: 1/7;
        /* padding-bottom: 5rem; */
       
     

    }

    .contentWrapper {
        padding: 0;
    }

}