.desktop {
    display: none;
}

.mobile {
    display: block;
    background-color: var(--color-gray-05);
}

.contentWrapper {
    padding: 0 2rem;
    /* background: red; */
}
.mobileHeader {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-top: 2rem;
    /* padding-top: 2rem;
    padding-bottom: 2rem; */
}

.headerTitle {
    color: var(--color-gray-01);
}

.subtitle {
    color: var(--color-gray-03);
}

.headerDescription {
    color: var(--color-gray-02);
    /* margin-top: 1rem;
    margin-bottom: 2rem; */
}

.listDetails {
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin: 0 2rem;
    /* padding-top: 2rem;
    padding-bottom: 1rem; */
}

.countStatement {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    border-top: 1px solid var(--color-gray-03);
    border-bottom: 1px solid var(--color-gray-03);
    padding: 2rem 0;
    margin: 2rem 0;

}

.buttonsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    padding: 1rem 2rem 3rem 2rem;
}

.buttonsWrapper button {
    width: max-content;
}

.description {
    padding: 0 0 2rem 0;
}

/* .description p{
    background-color: aqua;
    display: flex;
} */

@media (min-width: 768px) {
    .contentWrapper {
        padding: 0 4rem;
    }
    .listDetails {
   
        margin: 0 4rem;
        /* padding-top: 2rem;
        padding-bottom: 1rem; */
    }
    .buttonsWrapper {
  
        padding: 1rem 4rem 3rem 4rem;
    }
    /* .countStatement {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        background-color: var(--color-gray-05);
        border: 1px solid var(--color-gray-04);

        padding: 2rem 3rem;
        border-radius: 40rem;
        border: none;
        width: max-content;
    } */

}

@media (min-width: 1024px) {
    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }

    .desktopGridContainer {

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, auto);
    }

    .header {
        grid-row: 1/3;
        grid-column: 1/7;

        display: flex;
        flex-direction: column;
        /* gap: 1rem; */
    }

    .heroImage {
        grid-row: 1/-1;
        grid-column: 7/-1;
        justify-self: end;
    }

    .desktopListDetails {
        grid-row: 3/4;
        grid-column: 1/7;

        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .buttonsWrapper {
        grid-row: 3;
        grid-column: 1/7;
        align-self: end;
        padding: 0;
    }

    .lgManImageWrapper {
        grid-column: 7/-1;
        grid-row: 1/-1;
        justify-self: end;
    }

    .lgManImage {
        height: 50rem;
        /* width: 50rem; */

    }

    .lgManImage img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .imageWrapper {
        height: 50rem;
        /* align-self: end; */
        width: auto;
    }

    .imageWrapper img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        display: flex;
    }

    /* .countStatement {
        width: 60rem;
    } */
    .countStatement {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        background-color: var(--color-gray-05);
        border: 1px solid var(--color-gray-04);
       /* background: #ffbe0c; */

        padding: 1.5rem 3rem;
        border-radius: 40rem;
        border: none;
        width: max-content;
    }

    .innerGridWrapper {
        padding: 4rem 0 4rem 0;

    }
    .contentWrapper{
        padding: 3rem 4rem;
    }
}

@media (min-width: 1920px) {
    .contentWrapper {
        padding: 3rem 0;
    }
}
/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/