.printScreenPage {
    display: none;
}

/* .printScreenCoverContainer{
    display: none;
}

.printScreenBodyContainer {
    display: none;
} */


@media print {
    .printScreenPage {
        display: block;
        width: 100%;
        margin: 0;
    }

    .printScreenBodyContainer {
        display: block;
        width: 100%;
        margin: 0;
    }

    .printScreenCoverContainer {
        display: block;
        width: 100%;
        margin: 0;
    }

    /* .printScreenContainer {
        display: block;
        width: 100%;
        margin: 0;
    } */

    .printScreenCoverContainer {
        page-break-after: always;
    }

    .printScreenCoverWrapper {
        /* background-image: url('/public/assets/image/backgrounds/main/studio-kitchen-01.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        page-break-after: always; */
        background-image: url('/public/assets/image/backgrounds/main/studio-kitchen-01.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .header,
    .footer {
        /* position: absolute; */
        width: 100%;
        padding: 1cm;

    }

    /* .header {
        top: 5%;
        padding: 1cm;
    
    }

    .footer {
        bottom: 0;
        padding: 0 1cm;
    } */
    .header {
        padding-top: 2cm;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .footerContent {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .legalText {
        font-size: 1rem;
    }

    .legalTextWrapper {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1cm;
    }

    .logoWrapper {
        height: 4cm;
        width: 4cm;
        /* margin: 0 auto; */
    }

    .logoWrapper img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    body {
        background-color: var(--color-gray-06);
    }

    .productPage {
        page-break-before: always;
        /* padding: 2cm; */
        padding: 2rem;
        width: 100%;
        box-sizing: border-box;
        page-break-before: always;
        /* page-break-before: always;
        height: 100%;
        padding: 2rem; */
    }
.productPageTitleWrapper{
    padding: 2rem ;
}
/* .productPageTitle{
    font-size: 2rem;
} */
    .productHeaderText {
        padding: 1rem 2rem;
        border-top: 1px solid var(--color-gray-04);
        border-bottom: 1px solid var(--color-gray-04);
    }

    .productTitle {
        font-size: 1.4rem;
        color: var(--color-red-02);font-weight: 600;
    }

    .productSubtitle {
        font-size: 1.4rem;
        
    }

    .productImage {
        padding: 1rem 2rem;
        height: 10rem;
        width: 15rem;
        display: flex;
    }

    .productImage img {

        height: 100%;
        width: auto;
        object-fit: contain;
    }

    .productSpecs {
        padding: 1rem 2rem;
        border-top: 1px solid var(--color-gray-04);
     
    }

    .productTechLogos {
        border-top: 1px solid var(--color-gray-04);
        padding: 1rem 0 2rem 0 ;
   
        gap: 3rem;
          border-bottom: 1px solid var(--color-gray-04);
    }

    .qrCodeSection{
        border-top: 1px solid var(--color-gray-04);
        /* border-bottom: 1px solid var(--color-gray-04); */
        padding-bottom: 1rem;

    }
    .resourceTitle{
        display: flex;
        color: var(--color-red-02);
        margin-bottom: .2rem;
        font-weight: 600;
    }
    .qrCodeSectionTitleWrapper{
        margin-top: 1rem;
        padding-bottom: 1rem;
    }
    .qrCodeSectionTitle{
        font-size: 1.2rem;
        color: var(--color-gray-01);
        /* padding-bottom: 1px; */
        
    }
    .qrCodesContainerWrapper {
        display: flex;
        flex-direction: column;
        /* gap: 1rem; */
    }

    .qrCodesWrapper{
        display: flex;
        flex-direction: column;
    }
    .qrCodeTitle{
        padding-bottom: 1rem;
        font-size: 1.4rem;
        color: var(--color-red-02);
    }
    .productQrcodes {
        
    }

    .qrCodeItems{
        display: flex;
        flex-direction: row;
        gap: .5rem;
    }
    /* 
    .qrcodeWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
    } */
}

@page :first {
    size: A4;
    margin: 0;
}

@page {
    size: A4;
    margin: 0;

}