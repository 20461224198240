.portalContainer{
    /* background-color: var(--color-gray-01); */
    /* padding: 4rem; */
    /* display: flex; */
    /* height: 100%; */
    /* background-color: aqua; */
    /* padding: 4rem; */

}
.portalWrapper{
    display: grid;
    /* grid-template-columns: repeat(12, 1fr); */
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(4, auto);
    /* grid-template-rows: repeat(12, auto); */
    width: 100%;
    

}
.portalTopNavWrapper{
    grid-column: 1/-1;
    grid-row: 1;
    /* width: 100%; */
    /* border-bottom: 2px solid var(--color-gray-04); */
}

.portalSideNavWrapper{
    display: none;
}

.portalLayoutChildrenWrapper {
    grid-column: 1/-1;
    grid-row: 2;
    /* margin-bottom: 4rem; */
    /* grid-row: 2/-1; */
    /* min-height: 100%;   */
    /* background-color: red; */
   
}
.portalLayoutChildrenWrapper {
    grid-column: 1/-1;
    grid-row: 3;
    /* grid-row: 2/-1; */
    min-height: 100%;  
}
.portalFooterWrapper{
    grid-column: 1/-1;
    grid-row: 4;
    padding-top: 4rem;

}
@media (min-width: 1280px) {
    .portalSideNavWrapper{
        display: block;
        grid-column: 1/6;
        grid-row: 2/-1;
        height: 100%;
        /* padding: 2rem; */
    }
    .portalLayoutChildrenWrapper {
        grid-column: 6/-1;
        grid-row: 2/-1;
        height: 100%;
        /* min-height: 100%; */
       
    }
}
/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/