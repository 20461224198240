
.portalDataFilterBar{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
@media (min-width: 1920px) {
    .portalDataFilterBar{
        
        flex-direction: row;
    }
}
/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/