.mainContainer{
    /* padding: 2rem 0; */
  
}
.headerWrapper{
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.headerCountWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.bodyWrapper{
    padding: 2rem 0;
   /* background-color: var(--color-gray-04); */
   
}
.resourceGroupWrapper{
    /* background-color: var(--color-gray-05); */
    border-top: 1px solid var(--color-gray-04);
    padding: 2rem;
}
.groupContainer{}
.groupHeader{
    padding-bottom: 2rem;
}
.groupHeaderTitle{}
/* .groupFormElementsWrapper{
    width: 50%;
} */
.formElementsInputs{}
.formElementsUploader{}
.groupFooter{}
.footerButtonWrapper{
    display: flex;
}
.footerButtonWrapper button{
    width: max-content;
}



.groupTitle{
    /* padding: 1rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.formElementsWrapper{
    display: flex;
    flex-direction: column;
   /* align-items: center; */
 
    border: 1px solid black;
    width: 50%;
}
.inputsWrapper{

    display: flex;
    flex-direction: column;
}
.uploadsWrapper{
 
}
.buttonWrapper{
    display: flex;
}
.buttonWrapper button{
    max-width: max-content;
}

.groupContainer{
 
}
.groupFormElements{
    display: flex;
    flex-direction: column;
}

