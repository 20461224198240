.actionIconContainer {
  /* background-color: var(--color-gray-03); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-around;
    align-items: start; */
}

.actionIconContainer svg {
  height: 2rem;
  width: 2rem;
  display: flex;
  margin-right: 1rem;
  transform: scale(1);
  transition: transform 0.1s ease, border-color 0.1s ease;
  /* border-bottom: 1px solid transparent; */
}

.actionIconContainer svg:hover {
  cursor: pointer;
  transform: scale(1.05);

}


.greenPill{
  background-color: rgb(219, 255, 227);
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
 
  /* border: 1px solid rgb(0, 255, 55); */
  color:black;
}

.redPill{
  background-color: rgb(255, 216, 216);
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid var(--color-red-03); */
  color: black;
}
.yellowPill{
  background-color: rgb(239, 245, 253);
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border:1px solid blue; */
  color: black;

}
.greenPill:hover, .redPill:hover, .yellowPill:hover{
cursor: pointer;
}

.greenPill span,
.redPill span,
.yellowPill span{
  transform: scale(1);
  transition: transform 0.1s ease, border-color 0.1s ease;
  border-bottom: 2px solid transparent;
}

.greenPill span:hover,
.redPillspan:hover,
.yellowPill span:hover{
  transform: scale(1.01);
  border-bottom: 2px solid var(--color-red-03);
}


/* TABLE LINK */

.tableLink{
  transform: scale(1);
  transition: transform 0.1s ease, border-color 0.1s ease;
  border-bottom: 1px solid transparent;
}
.tableLink > a{
  color: var(--color-gray-02);
}
.tableLink:hover{
  /* transform: scale(1.05); */
  border-bottom: 2px solid var(--color-red-03);
}

.tableContainer{
  /* margin-bottom: 30rem; */
  overflow-x: auto;
}

.mainTableComponent{
 height: max-content;
 /* background-color: aqua;
 padding: 2rem; */
 /* height: 100rem; */
 /* padding: 2rem 0; */
 /* margin-bottom: 4rem; */
}

@media (min-width: 768px) {
  .mainTableComponent{
    height: max-content;
    min-height: 85rem;
    /* margin-bottom: 8rem; */
   }
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}

@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/