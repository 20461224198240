.mainFeedbackContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    padding-top: .6rem;
    color: var(--color-red-03);
}
.feedbackText{
    font-size: 1.6rem;
    font-family: var(--text-semibold);
}
.feedbackIcon svg{
    height: 1.8rem;
    width: 1.8rem;
    display: flex;
}