.warrantiesPageContainer {}

.pageHeaderContainer {
    padding:  0;
}
.shortDescription {
    display: block;
}

.longDescription {
    display: none;
}

.contentWrapper {
    padding: 0rem 2rem 2rem 2rem;
}

.heroGridWrapper {
    min-height: 20rem;
    display: flex;
    align-items: center;
    /* width: 80%; */
    /* padding-bottom: 2rem; */
}
.gridItem1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.gridItem2 {

    display: none;
}
.subtitle {
    color: var(--color-gray-02);
}

.title {
    color: var(--color-gray-01);
}

.description {
    color: var(--color-gray-03);
}

.bodySection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
    padding: 2rem 0;
}

.warrantyCard {
    background-color: var(--color-gray-07);
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    /* padding: 4rem 6rem; */
    padding: 3rem;
    border-radius: 2rem;
    border: 1px solid var(--color-gray-04);
}

.warrantyCardTitle {
    padding-bottom: .5rem;
    color: black;
    font-family: var(--text-semibold);
}
.warrantyItem{
    color: var(--color-gray-02);
}

.warrantiesSection {}

.warrantyItemListWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--color-gray-01);
    /* list-style: inside;
    list-style-position: inside; */
}


.bodySection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    gap: 2rem;
    grid-column: 1/-1;


}


@media (min-width: 1280px) {
    /* .bodySection {
        grid-template-columns: repeat(auto-fill, minmax(45rem, 1fr));
    } */

    .warrantyCard {
        padding: 4rem;
        border-radius: 4rem;
    }
    .contentWrapper {
        padding: 1rem 2rem 3rem 2rem;
    }
    .heroGridContainer {

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        height: auto;
        justify-content: center;
        align-items: center;

        /* background-color: var(--color-gray-06);
        padding-top: 8rem;
        padding-left: 8rem;
        padding-right: 8rem; */
        /* grid-column: 1/-1;
        grid-row: 1; */
        /* padding: 4rem 8rem; */
    }

    .heroGridWrapper {
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        column-gap: 12rem;
        align-items: center;
        /* padding: 4rem 0; */
        /* min-height: 50rem; */
        width: 100%;

    }

    .gridItem1 {
        grid-column: 1/8;
    }

   
    .longDescription {
        display: block;
    }

    .shortDescription {
        display: none;
    }
}

@media (min-width: 1440px) {
    .contentWrapper {
        padding: 0 4rem;
    }
    .contentWrapper {
        padding: 1rem 4rem 4rem 4rem;
    }

    .gridItem1 {
        grid-column: 1/6;
        grid-row: 1;
    }

    .gridItem2 {
        display: flex;
        /* border-top-left-radius: 100rem;
        border-top-right-radius: 100rem; */

        grid-column: 6/-1;
        justify-content: flex-end;
        /* background-image: url('/public/assets/image/backgrounds/main/lg-mom-shape.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; */
        grid-row: 1;
    }

    .gridItem2Image {
        height: 50rem;
        width: auto;
    }

    .gridItem2Image img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 1920px) {
    .contentWrapper {
        padding: 2rem 0rem;
    }
    .gridItem1 {
        grid-column: 1/6;
    }
    .gridItem2Image {
        height: 70rem;
    }
    
}

/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 834px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/