.colorListWrapper {
    display: flex;
    align-items: center;
    
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 2rem;
}

.colorListItem {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.colorSwatch {
    display: flex;
    width: 30px;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100rem;
    border: 1px solid var(--color-gray-04);
}

@media (min-width: 430px) {
    .colorListWrapper {
        display: flex;
        flex-direction: row;
        gap: 4rem;
        justify-content: space-between;
        padding-bottom: 4rem;
        /* justify-content: flex-end; */
    }

    .colorListItem {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .colorSwatch {
        display: flex;
        width: 50px;
        height: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 100rem;
  
    }
}

@media (min-width: 768px) {
    .colorListWrapper {
        padding-bottom: 3rem;
    }

   
}
@media (min-width: 1280px) {
    .colorListWrapper {
        display: flex;
        flex-direction: row;
        gap: 4rem;
        justify-content: flex-start;
        /* padding-top: 4rem; */
        padding-bottom: 0;

    }
}