
.noDataTableContainer{
    background-color: var(--color-gray-05);
    background-color: white;
    border: 1px solid var(--color-gray-04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 1rem;
    /* height: 33rem; */
    height: 50vh;
}
.noDataTextWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.noDataIconWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.noDataIcon1{
    height: 4.5rem;
    width: 4.5rem;
    background: rgb(255, 216, 216);
    border: 10px solid rgb(255, 216, 216);
    border-radius: 10rem;
}
.noDataIcon2{
    height: 10rem;
    width: 10rem;
}
.noDataIcon1 svg, .noDataIcon2 svg{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
@media (min-width: 430px) {
    .noDataTableContainer{
        height: 70vh;
    }
}


/* --------------------------------------------------------------------------------------- */
/* MEDIA QUERY REFERENCE */
/* --------------------------------------------------------------------------------------- */
/* 
@media (min-width: 430px) {}
@media (min-width: 768px) {}
@media (min-width: 1024px) {} - MOBILE / DESKTOP Breakpoint
@media (min-width: 1280px) {}
@media (min-width: 1440px) {}
@media (min-width: 1920px) {} 
*/